import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { getMe, getToken } from '../store/auth/authSlice';

const ProtectedRoute = () => {
    const token = useSelector(getToken)
    const user = useSelector(getMe)

    if (!token && !user) {
        return <Navigate to="/" replace />;
    }
    return <Outlet />;
}

export default ProtectedRoute