import { useEffect, useRef, useState } from "react";
import UploadImage from "../../components/UI/UploadImage";
import { CreateFormHeader } from "../../components/parts/CreateFormHeader";
import DrawingComponent from "../../components/parts/DrawingComponent";
import { useSelector } from "react-redux";
import Loader from "../../components/UI/Loader";
import { Link, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addQuizHintFilesByQuizIdFeth, editQuizHintFilesByQuizIdFeth, getQuizHintsByQuizIdFeth } from "../../store/quizzes/quizzesThunks";
import { getImageSrc } from "../../helpers/utils";
import { toast } from "react-toastify";
import { setHasEditChanges } from "../../store/auth/authSlice";


export const QuizCreateHints = () => {
    const conf = {
        title: 'Hints',
    }
    const { id } = useParams()
    const [quizId, quiz] = useOutletContext();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { quizeHints, isLoading } = useSelector((state) => state.quizzes);
    const [images, setImages] = useState([])
    const [selectedImage, setSelectedImages] = useState(null)
    const [mobileImagesPicker, setMobileImagesPicker] = useState(true)
    const [selectedHintsIndex, setSelectedHintsIndex] = useState(null)
    const [hints, setHints] = useState([])
    const drawingRef = useRef();
    const [selectedHint, setSelectedHint] = useState({})
    // const handleExportCanvas = () => {
    //     const url = drawingRef.current.toDataURL();
    //     
    // };

    // useEffect(()=> {

    //     // setSelectedHint(...hints.filter((item, index) => index === selectedHintsIndex))
    //     setSelectedHint(hints[selectedHintsIndex])
    // }, [selectedHintsIndex])
    useEffect(() => {
        if (quizeHints) {
            setImages(quizeHints.map(item => ({
                id: item.id,
                title: item.name || '',
                preview: getImageSrc(item.fileName),
                type: `image/${item.fileName.split('.').pop()}`
            })))
        }
    }, [quizeHints])
    useEffect(() => {
        if (quizId) {
            dispatch(getQuizHintsByQuizIdFeth(quizId))
        }
    }, [quizId])
    useEffect(() => {
        images.forEach((item, index) => {
            if (item.path && !hints[index]) {
                setHints(prev => ([
                    ...prev,
                    {
                        "hintTexts": [],
                        "hintMarkers": []
                    }
                ]))
            }
        })
    }, [images])
    useEffect(() => {
        setHints(quizeHints)
    }, [quizeHints])
    // const handleUploadImages = (images) => {
    //     
    //     setImages(images)
    // }

    const handleSelectImage = (selectedImage, index) => {
        setSelectedImages(selectedImage);
        setSelectedHintsIndex(index)
        setMobileImagesPicker(false)
    };
    const validate = () => {
        let error = false
        
        hints.forEach(item => {
            item.hintTexts.forEach(hintText => {
                if (hintText.content.trim().length === 0 || !hintText.content) {
                    error = true
                }
            })
        })

        return error
    }

    const handleSubmit = async () => {
        if(hints.length === 0) {
            toast.error('Add hints first')
            return
        }
        if (validate()) {
            toast.error('All hints must have a description')
            return
        }
        
        const newhints = hints.map((item, index) => ({
            ...item,
            name: images[index].title || null,
            hintTexts: item.hintTexts.map(hint => {
                if (String(hint.id).startsWith('my')) {
                    const { id, ...rest } = hint;
                    return rest;
                }
                return hint;
            })
        }))

        const media = images.filter(item => !item.id)
        const hints_data = newhints.filter(item => !item.id)
        const existing_hints = newhints.filter(item => item.id)
        const formData = new FormData()
        media.forEach(item => {
            formData.append('images', item)
        })
        if (hints_data && hints_data.length) {
            formData.append('hints_data', JSON.stringify(hints_data))
        }

        let res = null

        if (quizeHints.length) {
            if (existing_hints && existing_hints.length) {
                formData.append('existing_hints', JSON.stringify(existing_hints))
            } else {
                formData.append('existing_hints', JSON.stringify([]))
            }
            res = await dispatch(editQuizHintFilesByQuizIdFeth({ id: quizId, body: formData })).unwrap()
        } else {
            res = await dispatch(addQuizHintFilesByQuizIdFeth({ id: quizId, body: formData })).unwrap()
        }
        if (res) {
            if (id) {
                navigate(`/quiz-edit/${id}/quiz`)
            } else {
                navigate('/quiz-create/quiz')
            }
            dispatch(setHasEditChanges(false))
        }
    }
    console.log(mobileImagesPicker)
    if (isLoading || !quizeHints || !hints) return (
        <div className="wrapper header-fixed sidebar-left-fixed flex items-center justify-center flex-auto">
            <Loader />
        </div>
    )
    return (
        <>
            <CreateFormHeader config={conf} />
            <div className="wrapper header-fixed sidebar-left-fixed">
                <main className="content">
                    <div className="quiz pt-0" id="drawContainer" data-active="">
                        <div className="quiz__content">
                            <div className="quiz__body">
                                <div className="container container--full container-my-0">
                                    <div className="editor-row">
                                        <div className={`editor-col editor-col--sidebar editor-col--sidebar-drowpart ${mobileImagesPicker ? '--active' : ''}`}>
                                            <div className="flex flex-col gap-[16px]">
                                                <UploadImage
                                                    defaulImages={images}
                                                    onChange={(e) => {
                                                        dispatch(setHasEditChanges(true))
                                                        setImages(e)
                                                    }}
                                                    onSelect={handleSelectImage}
                                                    multiple={true} isRow={true} isNarrow={true}
                                                    setDelete={setHints}
                                                />
                                            </div>
                                        </div>
                                        <div className="editor-col editor-col--content !overflow-hidden">
                                            <DrawingComponent
                                                ref={drawingRef}
                                                image={selectedImage}
                                                hintsSelected={hints[selectedHintsIndex]}
                                                selectedIndex={selectedHintsIndex}
                                                setItems={(e) => {
                                                    dispatch(setHasEditChanges(true))
                                                    setHints(e)
                                                }
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="quiz__footer bg-white">
                                <div className="container">
                                    <Link to={'/quiz'} className="btn btn--shadow gap-[10px] btn--md radius-5">
                                        <span className="info text-[13px] font-[500]">Cancel</span>
                                    </Link>
                                    <button onClick={() => setMobileImagesPicker(prev => !prev)} className="btn btn--primary gap-[10px] md:hidden btn--md radius-5">
                                        Select image
                                    </button>
                                    <button onClick={handleSubmit} className="btn btn--primary gap-[10px] btn--md radius-5">
                                        <span className="info text-[13px] font-[500] sm:flex hidden">Continue</span>
                                        <span className="ico">
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17.4163 10.9998H4.58301" stroke="currentColor" strokeWidth="1.5"
                                                    strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M12.833 15.5833L17.4163 11" stroke="currentColor" strokeWidth="1.5"
                                                    strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M12.833 6.4165L17.4163 10.9998" stroke="currentColor" strokeWidth="1.5"
                                                    strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>

                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}
