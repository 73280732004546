import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import CheckBoxGroup from '../UI/formElements/CheckBoxGroup';
import { useClickOutside } from '../../hooks/useClickOutside';
import Filters from './Filters';



const SidebarFilters = ({ filters, links, defaultCheked = [], tips, onChange = () => { }, children }) => {
    // const [checkedOptions, setCheckedOptions] = useState([]);
    // const [showAll, setShowAll] = useState({});
    
    // const maxItemsForShow = 10
   
    const [isOpen, setIsOpen] = useState(false)

    const handleSidebarClose = () => {
        document.querySelector("body").classList.remove("lock")
        setIsOpen(false)
    }
    const sidebarRef = useClickOutside(handleSidebarClose)
    const handleSidebarToggle = () => {
        if (isOpen) {
            handleSidebarClose()
        } else {
            document.querySelector("body").classList.add("lock")
            setIsOpen(true)
        }
    }
    // const isChecked = (option) => checkedOptions.includes(option.name);
    // const toggleShowAll = (title) => {
    //     setShowAll(prevShowAll => ({
    //         ...prevShowAll,
    //         [title]: !prevShowAll[title]
    //     }));
    // };

    

    // useEffect(() => {

    //     setCheckedOptions(defaultCheked)

    // }, [defaultCheked])


    return (
        <aside className={`filters-sidebar section-filters__aside ${isOpen ? "sidebar-is-open" : ""}`} ref={sidebarRef}>
            <div className="filters-sidebar__container">
                {children}
                {links && links.length ?
                    <ul className="page-menu-list">
                        {links.map((link, index) => (
                            <li key={index}>
                                <NavLink to={link.to} className={({ isActive }) => `btn ${isActive ? "active" : ""}`} end >
                                    <span className="ico">{link.ico}</span>
                                    <span>{link.label}</span>
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                    :
                    null
                }
                <Filters {...{ filters, defaultCheked, onChange }} />
                {/* {
                    filters && filters.length ?
                        filters.map((filter, index) => (
                            <div key={index} className="section-filters__aside--item">
                                <span className="sidebar-label">{filter.title}</span>
                                <div className="checkboxes-list">
                                    {filter?.checkboxOptions?.slice(0, showAll[filter.title] ? filter?.checkboxOptions?.length : maxItemsForShow).map((option, idx) => (
                                        <CheckBoxGroup key={idx} value={option.name} checked={isChecked(option)} onChange={() => onChange(option, filter?.type)}>
                                            <p className="check-text">{option.name}</p>
                                        </CheckBoxGroup>
                                    ))}
                                </div>
                                {
                                    filter?.checkboxOptions?.length > maxItemsForShow ?
                                        <button className="btn text-[13px] justify-start color-danger" onClick={() => toggleShowAll(filter.title)}>
                                            {showAll[filter.title] ? 'Show Less' : 'Show More'}
                                        </button>
                                        :
                                        null
                                }
                            </div>
                        ))
                        :
                        null
                } */}

                {
                    tips && tips.length ?
                        <div className="card card-custom py-[14px] px-[18px] rounded-[6px]">
                            <div className="card-header mb-[12px]">
                                <h5 className="text-[12px] uppercase color-secondary-2">Exploration Tips</h5>
                            </div>
                            <div className="card-body">
                                <ul className="flex flex-col gap-[10px]">
                                    {tips.map(tip => (
                                        <li key={tip.id}>
                                            <a href="#" className="btn before-point text-[13px] before-point--secondary btn-keyword text-left justify-start">
                                                <span>{tip.title}</span>
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        :
                        null
                }
            </div>
            <button onClick={handleSidebarToggle} className="btn btn--primary btn--square btn--md sidebar-action-btn">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 512 512">
                    <path fill="currentColor" d="M3.9 54.9C10.5 40.9 24.5 32 40 32H472c15.5 0 29.5 8.9 36.1 22.9s4.6 30.5-5.2 42.5L320 320.9V448c0 12.1-6.8 23.2-17.7 28.6s-23.8 4.3-33.5-3l-64-48c-8.1-6-12.8-15.5-12.8-25.6V320.9L9 97.3C-.7 85.4-2.8 68.8 3.9 54.9z" />
                </svg>
            </button>
        </aside >
    );
};

export default SidebarFilters;
