import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { toast } from 'react-toastify';
import { addCotanctEmail, clearToken, deleteCotanctEmail, updateCotanctEmail } from './authSlice';

// const clearToken = () => {
//     window.localStorage.removeItem('authToken');
//     window.sessionStorage.removeItem('authToken');
// }

export const loginFetch = createAsyncThunk(
    '/login/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.auth.login(payload);
            if (res.success) {
                return res.data;
            } else {
                // toast.error(res.message)
                clearToken();
            }
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const finalizeLoginFetch = createAsyncThunk(
    '/finalize-login/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.auth.finalizeLogin(payload);
            if (res.success) {
                return res.data;
            } else {
                toast.error(res.message)
                clearToken();
            }
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const generateFaFetch = createAsyncThunk(
    '/generateFa/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.auth.generateFa(payload);
            if (res.success) {
                return res.data;
            } 
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const verifyFaFetch = createAsyncThunk(
    '/verifyFa/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.auth.verifyFa(payload);
            if (res.success) {
                return res;
            } 
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const login2faFetch = createAsyncThunk(
    '/login2fa/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.auth.login2fa(payload);
            if (res.success) {
                return res.data;
            } 
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);

export const getMeFetch = createAsyncThunk(
    '/get-me/',
    async (payload, { rejectWithValue, abort, getState }) => {
        try {
            const token = getState().auth.token;
            if (!token) {
                return abort();
            }
            const res = await api.users.getMe();
            if (res.success) {
                return res.data;
            } else {
                clearToken();
                // toast.error(res.message)
            }
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);

export const logoutFetch = createAsyncThunk(
    '/logout/',
    async (payload, { rejectWithValue, abort, getState }) => {
        try {
            const token = getState().auth.token;
            if (!token) {
                return abort();
            }
            const res = await api.auth.logout();
            if (res.success) {
                clearToken()
                toast.success("Logout success")
                return res;
            } else {
                toast.error(res.message)
                return abort();
            }
        } catch (err) {
            
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);

export const registerFetch = createAsyncThunk(
    '/register/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.auth.register(payload);
            if (res.success) {
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);

export const updateProfileFetch = createAsyncThunk(
    '/update-profile/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.users.updateProfile(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);

export const forgotPasswordFetch = createAsyncThunk(
    '/forgot-password/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.auth.forgotPassword(payload);
            if (res.success) {
                toast.success(res.message)
            } else {
                toast.error(res.message)
            }
            return res.data

        } catch (err) {
            
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);

export const resetPasswordFetch = createAsyncThunk(
    '/reset-password/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.auth.resetPassword(payload);
            if (res.success) {
                toast.success(res.message)
            } else {
                toast.error(res.message)
            }
            return res.data
        } catch (err) {
            
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);


export const addCotactEmailFetch = createAsyncThunk(
    '/add-contact-email/',
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            const res = await api.contactEmails.addCotactEmail(payload);
            if (res.success) {
                dispatch(addCotanctEmail(res.data))
                toast.success("Email added")
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            return rejectWithValue(err?.response.data);
        }
    },
);
export const updateCotactEmailFetch = createAsyncThunk(
    '/update-contact-email/',
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            const res = await api.contactEmails.updateCotactEmail(payload);
            if (res.success) {
                dispatch(updateCotanctEmail(res.data))
                toast.success("Email update")
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            return rejectWithValue(err?.response.data);
        }
    },
);
export const deleteCotactEmailFetch = createAsyncThunk(
    '/delete-contact-email/',
    async (payload, { rejectWithValue, dispatch }) => {

        try {
            const res = await api.contactEmails.deleteCotactEmail(payload);
            if (res.success) {
                dispatch(deleteCotanctEmail(payload))
                toast.success("Email deleted")
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            return rejectWithValue(err?.response.data);
        }
    },
);
