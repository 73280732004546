import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import CheckBoxGroup from '../../components/UI/formElements/CheckBoxGroup'
import InputGroup from '../../components/UI/formElements/InputGroup'
import { useValidation } from '../../hooks/useValidation'
import { setTermToken, setToken } from '../../store/auth/authSlice'
import logo from "../../assets/img/logo.svg"
import logoDark from "../../assets/img/logo-footer.svg"
import { forgotPasswordFetch, loginFetch } from '../../store/auth/authThunks'
import ModalTemplate from './ModalTemplate'
import { useSelector } from 'react-redux'
import { apiConfig } from '../../api/config'
import ButtonLoader from '../UI/ButtonLoader'
import ReCAPTCHA from 'react-google-recaptcha'
import useRecaptcha from '../../hooks/useRecaptcha'
import { toast } from 'react-toastify'
import { ReactComponent as Linkknin } from '../../assets/img/linkedin_logo.svg'

const LoginModal = ({ open, handelClose, linkToRegister = true, handelForgor, handelModal2Fa }) => {
    const dispatch = useDispatch()
    const { isLocalload, siteTheme } = useSelector(state => state.auth)

    const [formData, setFormData] = useState({
        email: "",
        password: ""
    })
    const { getRecaptchaToken } = useRecaptcha('6Ld70TkqAAAAANeVGMJLRV4cZ5JDqPoCim3rZp_g');


    const [remember, setRemember] = useState(false)
    const [succesMessage, setSuccesMessage] = useState(false)

    const validators = {
        email: value => /\S+@\S+\.\S+/.test(value) ? true : 'Invalid email format',
    };
    const messages = {
        email: 'Email is required',
        password: 'Password is required',
    };
    const { errors, errorMessages, isErrorShown, setIsErrorShown } = useValidation(formData, {
        validators,
        messages
    });


    const handleSubmit = async (e) => {
        setSuccesMessage(false)
        e.preventDefault()
        if (errors) {
            setIsErrorShown(true)
            return;
        }
        const recaptchaToken = await getRecaptchaToken("LOGIN");
        if (!recaptchaToken) {
            toast.error('Failed to obtain reCAPTCHA token.')
        }

        const newData = {
            ...formData,
            recaptchaToken: recaptchaToken
        }
        const res = await dispatch(loginFetch(newData)).unwrap()

        // if(res?.success) {
        //     setSuccesMessage(res.message)
        //     if(remember) {
        //         document.cookie = "rememberMe=true; path=/"; 
        //         localStorage.setItem("remember", remember)
        //     }
        // }
        if (res?.token) {
            // const storage = remember ? localStorage : sessionStorage;
            // document.cookie = `rememberMe=${remember}; path=/`; 
            localStorage.setItem("remember", remember)
            localStorage.setItem("authToken", res?.token)
            dispatch(setToken(res?.token))
            handelClose()
        }
        if (res?.requires2FA) {
            console.log(res)
            localStorage.setItem("remember", remember)
            dispatch(setTermToken(res.tempToken))
            handelClose()
            handelModal2Fa()
        }
    }

    // const handleForgotPassword = async () => {
    //     if (errors?.email) return;
    //     await dispatch(forgotPasswordFetch({ email: formData.email }))
    // }

    const handleChange = (name, value) => {
        setFormData(prev => ({
            ...prev, [name]: value
        }))
    }

    return (
        <ModalTemplate open={open} handelClose={handelClose}>
            <div className="modal__body items-center">
                <div className="btn">
                    {/* <img src={require("../../assets/img/logo-modal-darck.png")} alt="" /> */}
                    <img src={siteTheme === 'dark' ? logoDark : logo} className='max-h-[62px]' alt="mediwise" />
                </div>
                <div className="modal__body-heading text-center">
                    <h2>Welcome Back</h2>
                    <p>Please enter your details to log in</p>
                </div>
                <div className="flex items-center flex-wrap gap-[9px] w-full">
                    <a href={`${apiConfig.baseURL}api/auth/linkedin`} className="btn btn--shadow btn--lg radius-5 flex-1">
                        <Linkknin />
                    </a>
                    <a href={`${apiConfig.baseURL}api/auth/google`} className="btn btn--shadow btn--lg radius-5 flex-1">
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.8055 10.5415H21V10.5H12V14.5H17.6515C16.827 16.8285 14.6115 18.5 12 18.5C8.6865 18.5 6 15.8135 6 12.5C6 9.1865 8.6865 6.5 12 6.5C13.5295 6.5 14.921 7.077 15.9805 8.0195L18.809 5.191C17.023 3.5265 14.634 2.5 12 2.5C6.4775 2.5 2 6.9775 2 12.5C2 18.0225 6.4775 22.5 12 22.5C17.5225 22.5 22 18.0225 22 12.5C22 11.8295 21.931 11.175 21.8055 10.5415Z" fill="#FFC107"></path>
                            <path d="M3.15295 7.8455L6.43845 10.255C7.32745 8.054 9.48045 6.5 12 6.5C13.5295 6.5 14.921 7.077 15.9805 8.0195L18.809 5.191C17.023 3.5265 14.634 2.5 12 2.5C8.15895 2.5 4.82795 4.6685 3.15295 7.8455Z" fill="#FF3D00"></path>
                            <path d="M12 22.5C14.583 22.5 16.93 21.5115 18.7045 19.904L15.6095 17.285C14.5718 18.0742 13.3038 18.501 12 18.5C9.39903 18.5 7.19053 16.8415 6.35853 14.527L3.09753 17.0395C4.75253 20.278 8.11353 22.5 12 22.5Z" fill="#4CAF50"></path>
                            <path d="M21.8055 10.5415H21V10.5H12V14.5H17.6515C17.2571 15.6082 16.5467 16.5766 15.608 17.2855L15.6095 17.2845L18.7045 19.9035C18.4855 20.1025 22 17.5 22 12.5C22 11.8295 21.931 11.175 21.8055 10.5415Z" fill="#1976D2"></path>
                        </svg>
                    </a>
                    <a href={`${apiConfig.baseURL}api/auth/facebook`} className="btn btn--shadow btn--lg radius-5 flex-1">
                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M22.3333 12.5C22.3333 6.98 17.8533 2.5 12.3333 2.5C6.81325 2.5 2.33325 6.98 2.33325 12.5C2.33325 17.34 5.77325 21.37 10.3333 22.3V15.5H8.33325V12.5H10.3333V10C10.3333 8.07 11.9033 6.5 13.8333 6.5H16.3333V9.5H14.3333C13.7833 9.5 13.3333 9.95 13.3333 10.5V12.5H16.3333V15.5H13.3333V22.45C18.3833 21.95 22.3333 17.69 22.3333 12.5Z" fill="#1877F2"></path>
                        </svg>
                    </a>
                </div>
                <div className="w-full">
                    <div className="row g-16">
                        <div className="col-12">
                            <InputGroup
                                wrapperClasses=' input--lg'
                                label={'Email address'}
                                type='email'
                                inputClasses='input--outline'
                                placeholder={'Enter your email...'}
                                value={formData.email}
                                error={isErrorShown && errors.email}
                                errorMessage={errorMessages.email}
                                onChange={(e) => handleChange("email", e.target.value)}
                            />
                        </div>
                        <div className="col-12">
                            <InputGroup
                                wrapperClasses=' input--lg'
                                label={'Password'}
                                type='password'
                                inputClasses='input--outline'
                                placeholder={'Enter your password...'}
                                value={formData.password}
                                error={isErrorShown && errors.password}
                                errorMessage={errorMessages.password}
                                onChange={(e) => handleChange("password", e.target.value)}
                            />
                        </div>
                        <div className="col-12">
                            <div className="flex flex-wrap justify-between gap-3">
                                <CheckBoxGroup
                                    checked={remember}
                                    onChange={() => setRemember(!remember)}
                                >
                                    <p className="check-text">
                                        Remember me
                                    </p>
                                </CheckBoxGroup>
                                <button onClick={handelForgor} type='button' className="btn color-danger text-[13px]">Forgot Password?</button>
                            </div>
                        </div>
                        {/* {succesMessage && <div className="col-12">
                            <p className='text-center text-[14px]'>{succesMessage}</p>
                        </div>} */}
                    </div>
                </div>
                <div className="flex flex-col w-full gap-[24px]">
                    <button type='button' disabled={isLocalload} className={`btn btn--primary btn--lg radius-5 w-full ${isLocalload ? 'disabled' : ''}`} onClick={handleSubmit} >

                        {isLocalload ? <ButtonLoader /> : 'Login'}
                    </button>
                    <div className="flex color-secondary items-center justify-center w-full text-[13px] gap-3">
                        <span>Don’t have an account?</span>
                        {linkToRegister
                            ?
                            <Link to={'/register'} className="color-danger">Create Account</Link>
                            :
                            <button type='button' onClick={handelClose} className="color-danger">Create Account</button>
                        }
                    </div>
                </div>
            </div>
        </ModalTemplate>
    )
}

export default LoginModal
