import React, { useEffect, useRef } from 'react'
import logo from "../../assets/img/logo-footer.svg"
import { Link } from 'react-router-dom'
import { getAllPages } from '../../store/pages/pagesSlice'
import { useSelector } from 'react-redux'
import { getFiltersForFooterFeth } from '../../store/filters/filtersThukns'
import { useDispatch } from 'react-redux'
import { getFilterFooter } from '../../store/filters/filtersSlice'
import { socialLinks } from '../../helpers/constants'


const Footer = () => {
    const maxCount = 5
    const pages = useSelector(getAllPages)
    const filters = useSelector(getFilterFooter)
    const divRef = useRef()
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getFiltersForFooterFeth())
    }, [])

    useEffect(() => {
        if (divRef.current) {
            divRef.current.setAttribute('xmlns:cc', 'http://creativecommons.org/ns#');
        }
    }, []);

    return (
        <footer className="footer">
            <div className="footer-top">
                <div className="container">
                    <div className="footer-top-body">
                        <div className="footer-top-body__info">
                            <a href="" className="footer-logo"><img src={logo} className='max-h-[30px]' alt="logo" /></a>
                            <p className="footer-desc">Some text here about medical educational platform RCVIR.</p>
                            <div className="footer-social-list">
                                <a href={socialLinks.linkedin} target='_blank' rel="noreferrer nofollow" className="btn btn--default-light btn--square-md  rounded-full">
                                    <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20.4701 2.00014H3.53006C3.33964 1.9975 3.15056 2.03239 2.97362 2.10282C2.79669 2.17326 2.63536 2.27786 2.49886 2.41065C2.36235 2.54344 2.25334 2.70182 2.17805 2.87675C2.10276 3.05167 2.06267 3.23972 2.06006 3.43014V20.5701C2.06267 20.7606 2.10276 20.9486 2.17805 21.1235C2.25334 21.2985 2.36235 21.4568 2.49886 21.5896C2.63536 21.7224 2.79669 21.827 2.97362 21.8975C3.15056 21.9679 3.33964 22.0028 3.53006 22.0001H20.4701C20.6605 22.0028 20.8496 21.9679 21.0265 21.8975C21.2034 21.827 21.3648 21.7224 21.5013 21.5896C21.6378 21.4568 21.7468 21.2985 21.8221 21.1235C21.8974 20.9486 21.9375 20.7606 21.9401 20.5701V3.43014C21.9375 3.23972 21.8974 3.05167 21.8221 2.87675C21.7468 2.70182 21.6378 2.54344 21.5013 2.41065C21.3648 2.27786 21.2034 2.17326 21.0265 2.10282C20.8496 2.03239 20.6605 1.9975 20.4701 2.00014ZM8.09006 18.7401H5.09006V9.74014H8.09006V18.7401ZM6.59006 8.48014C6.17632 8.48014 5.77953 8.31578 5.48697 8.02323C5.19442 7.73067 5.03006 7.33388 5.03006 6.92014C5.03006 6.5064 5.19442 6.10961 5.48697 5.81705C5.77953 5.5245 6.17632 5.36014 6.59006 5.36014C6.80975 5.33522 7.03223 5.35699 7.24293 5.42402C7.45363 5.49105 7.6478 5.60183 7.81272 5.7491C7.97763 5.89637 8.10958 6.07682 8.19993 6.27862C8.29027 6.48043 8.33698 6.69904 8.33698 6.92014C8.33698 7.14124 8.29027 7.35985 8.19993 7.56166C8.10958 7.76346 7.97763 7.94391 7.81272 8.09118C7.6478 8.23845 7.45363 8.34923 7.24293 8.41626C7.03223 8.48329 6.80975 8.50505 6.59006 8.48014ZM18.9101 18.7401H15.9101V13.9101C15.9101 12.7001 15.4801 11.9101 14.3901 11.9101C14.0527 11.9126 13.7242 12.0184 13.4489 12.2133C13.1735 12.4082 12.9645 12.6828 12.8501 13.0001C12.7718 13.2352 12.7379 13.4827 12.7501 13.7301V18.7301H9.75006V9.73014H12.7501V11.0001C13.0226 10.5272 13.419 10.1377 13.8965 9.87334C14.374 9.60902 14.9146 9.47999 15.4601 9.50014C17.4601 9.50014 18.9101 10.7901 18.9101 13.5601V18.7401Z" fill="currentColor" />
                                    </svg>
                                </a>
                                <a href={socialLinks.facebook} target='_blank' rel="noreferrer nofollow" className="btn btn--default-light btn--square-md rounded-full">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20 11C20 6.032 15.968 2 11 2C6.032 2 2 6.032 2 11C2 15.356 5.096 18.983 9.2 19.82V13.7H7.4V11H9.2V8.75C9.2 7.013 10.613 5.6 12.35 5.6H14.6V8.3H12.8C12.305 8.3 11.9 8.705 11.9 9.2V11H14.6V13.7H11.9V19.955C16.445 19.505 20 15.671 20 11Z" fill="currentColor" />
                                    </svg>
                                </a>
                                <a href={socialLinks.tweeter} target='_blank' rel="noreferrer nofollow" className="btn btn--default-light btn--square-md rounded-full">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.1757 3H18.9357L12.9058 9.8925L20 19.2698H14.4455L10.0955 13.5817L5.117 19.2698H2.3555L8.8055 11.8973L2 3.00075H7.6955L11.6278 8.19975L16.1757 3ZM15.2075 17.6182H16.7367L6.8645 4.56525H5.2235L15.2075 17.6182Z" fill="currentColor" />
                                    </svg>
                                </a>
                                <a href={socialLinks.youtube} target='_blank' rel="noreferrer nofollow" className="btn btn--default-light btn--square-md rounded-full">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 576 512"><path d="M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z" fill='currentColor' /></svg>
                                </a>
                            </div>
                            <div ref={divRef} className='footer-desc'>
                                This work is licensed under
                                <a
                                    href="https://creativecommons.org/licenses/by-nc-sa/4.0/?ref=chooser-v1"
                                    target="_blank"
                                    rel="license noopener noreferrer"
                                    style={{ display: 'inline-block' }}
                                >
                                    CC BY-NC-SA 4.0
                                    <img
                                        style={{
                                            height: '22px',
                                            marginLeft: '3px',
                                            verticalAlign: 'text-bottom'
                                        }}
                                        src="https://mirrors.creativecommons.org/presskit/icons/cc.svg?ref=chooser-v1"
                                        alt=""
                                    />
                                    <img
                                        style={{
                                            height: '22px',
                                            marginLeft: '3px',
                                            verticalAlign: 'text-bottom'
                                        }}
                                        src="https://mirrors.creativecommons.org/presskit/icons/by.svg?ref=chooser-v1"
                                        alt=""
                                    />
                                    <img
                                        style={{
                                            height: '22px',
                                            marginLeft: '3px',
                                            verticalAlign: 'text-bottom'
                                        }}
                                        src="https://mirrors.creativecommons.org/presskit/icons/nc.svg?ref=chooser-v1"
                                        alt=""
                                    />
                                    <img
                                        style={{
                                            height: '22px',
                                            marginLeft: '3px',
                                            verticalAlign: 'text-bottom'
                                        }}
                                        src="https://mirrors.creativecommons.org/presskit/icons/sa.svg?ref=chooser-v1"
                                        alt=""
                                    />
                                </a>
                            </div>
                        </div>
                        <div className="footer-top-body__menu-list ">
                            <div className="row g-80">
                                <div className="footer-top-body__menu-list--item">
                                    <span className="pill rounded-full pill-default">Article Topics</span>
                                    {filters?.articleTopics?.length > 0 ? <ul className="footer-menu">
                                        {filters?.articleTopics?.slice(0, maxCount)?.map(topic => (
                                            <li key={topic.id}>
                                                <Link
                                                    to={`/articles?${topic.type}=${String(topic.name).replace(' ', '+')}`}>
                                                    {topic.name}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul> : null}
                                    {filters?.articleTopics?.length > maxCount - 1 &&
                                        <Link to={`/articles`} className="btn footer-show-more-btn">Show More</Link>
                                    }
                                </div>
                                <div className="footer-top-body__menu-list--item">
                                    <span className="pill rounded-full pill-default">Courses</span>
                                    {filters?.courses?.length > 0 ? <ul className="footer-menu">
                                        {filters?.courses?.slice(0, maxCount)?.map(topic => (
                                            <li key={topic.id}>
                                                <Link
                                                    to={`/courses?${topic.type}=${String(topic.name).replace(' ', '+')}`}>
                                                    {topic.name}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul> : null}
                                </div>
                                <div className="footer-top-body__menu-list--item">
                                    <span className="pill rounded-full pill-default">Cases</span>
                                    {filters?.cases?.length > 0 ? <ul className="footer-menu">
                                        {filters?.cases?.slice(0, maxCount)?.map(topic => (
                                            <li key={topic.id}>
                                                <Link
                                                    to={`/cases?${topic.type}=${String(topic.name).replace(' ', '+')}`}>
                                                    {topic.name}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul> : null}
                                    {filters?.cases?.length > maxCount - 1 &&
                                        <Link to={`/cases`} className="btn footer-show-more-btn">Show More</Link>
                                    }
                                </div>
                                <div className="footer-top-body__menu-list--item">
                                    <span className="pill rounded-full pill-default">Platform</span>
                                    <ul className="footer-menu">
                                        {pages && pages.map(item => (
                                            item.id !== 1 ? <li key={item.id}>
                                                <Link to={item.url} >
                                                    {item.name}
                                                </Link>
                                            </li> : null
                                        ))}
                                        <li>
                                            <Link to={'/anatomy'}>Anatomy</Link>
                                        </li>
                                        <li>
                                            <Link to={'/forums'}>Forums</Link>
                                        </li>
                                        <li>
                                            <Link to={'/quiz'}>Quizzes</Link>
                                        </li>

                                    </ul>
                                </div>
                                <div className="footer-top-body__menu-list--item">
                                    <span className="pill rounded-full pill-default">Resources</span>
                                    <ul className="footer-menu">
                                        <li>
                                            <Link to={'/materials'}>Materials Library </Link>
                                        </li>
                                        {pages && pages.map(item => (
                                            item.id === 1 ? <li key={item.id}>
                                                <Link to={item.url} >
                                                    {item.name}
                                                </Link>
                                            </li> : null
                                        ))}
                                        <li>
                                            <Link to={'/events'}>Event Calendar</Link>
                                        </li>
                                        <li>
                                            <Link to={'/pricing'}>Pricing Plan</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="footer-bottom-body">
                        <p>© 2024 RCVIR</p>
                        <ul className="footer-bottom-list">
                            <li><Link to={'/terms'}>Terms of Use</Link></li>
                            <li><Link to={'/privacy'}>Privacy Policy</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer