import React, { useEffect, useState } from 'react'
import { Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import LoginModal from '../../components/Modals/LoginModal'
import CheckBoxGroup from '../../components/UI/formElements/CheckBoxGroup'
import InputGroup from '../../components/UI/formElements/InputGroup'
import SelectGroup from '../../components/UI/formElements/SelectGroup'

import { ReactComponent as DeleteIcon } from "../../assets/img/ico-trash.svg"
import { ReactComponent as UploadIcon } from "../../assets/img/ico-upload.svg"

import { getFormData, getImageSrc } from "../../helpers/utils"
import logo from "../../assets/img/logo.svg"
import logoDark from "../../assets/img/logo-footer.svg"
import { useDispatch } from 'react-redux'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'swiper/css'
import 'swiper/css/pagination'
import { useValidation } from '../../hooks/useValidation'
import { registerFetch } from '../../store/auth/authThunks'
import { getAllProfessionsFetch } from '../../store/professions/professionsThunks'
import ButtonLoader from '../../components/UI/ButtonLoader'
import { InputPhoneCodeMask } from '../../components/UI/formElements/InputPhoneCodeMask'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { useSelector } from 'react-redux'
import useRecaptcha from '../../hooks/useRecaptcha'
import SelectGroupAsynk from '../../components/UI/formElements/SelectGroupAsynk'


const SignUp = () => {
	const dispatch = useDispatch()
	// const navigate = useNavigate()
	const { siteTheme } = useSelector(state => state.auth)
	const [goToHome, setGoToHome] = useState(false)
	const [formData, setFormData] = useState({
		phoneNumber: "",
		email: "",
		firstName: "",
		lastName: "",
		photo: "",
		professionId: null,
		fullName: "",
		password: "",
		confirmPassword: "",
	})
	const { getRecaptchaToken } = useRecaptcha('6Ld70TkqAAAAANeVGMJLRV4cZ5JDqPoCim3rZp_g');
	const [agree, setAgree] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const validators = {
		phone: value => isValidPhoneNumber('+' + String(value)) ? true : 'Invalid phone number format',
		email: value => /\S+@\S+\.\S+/.test(value) ? true : 'Invalid email format',
		firstName: value => /^[^0-9]*$/.test(value) ? true : 'First name must not contain numeric characters',
		lastName: value => /^[^0-9]*$/.test(value) ? true : 'Last name must not contain numeric characters',
		fullName: value => {
			if (/\d/.test(value)) {
				return 'Full name must not contain numeric characters';
			}
			const nameParts = value.trim().split(' ');
			if (nameParts.length < 2) {
				return 'Full name must contain at least a first and last name';
			}
			return true;
		},
		confirmPassword: (value, data) => value === data.password ? true : 'Passwords do not match',
		password: (value) => {
			const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
			return passwordRegex.test(value) ? true : 'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character';
		},
		photo: file => {
			if (!file) {
				return true;
			}
			if (!file?.type?.startsWith('image/')) {
				return 'Photo must be an image file (jpg, jpeg, png, gif)';
			}
			return true;
		},
	};

	const messages = {
		// phoneNumber: 'Phone number is required',
		email: 'Email is required',
		firstName: 'First name is required',
		lastName: 'Last name is required',
		professionId: 'Profession is required',
		fullName: 'Full name is required',
		password: 'Password is required',
		confirmPassword: 'Confirm password is required',
	};
	const { errors, errorMessages, isErrorShown, setIsErrorShown } = useValidation(formData, {
		validators,
		messages,
		exceptions: ['phoneNumber']
	});

	const [professions, setProfessions] = useState([])
	const [isProfessionsLoading, setIsProfessionsLoading] = useState(false)

	const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false)

	const handleLogin = () => {
		setIsLoginPopupOpen(prev => !prev)
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		if (errors) {
			setIsErrorShown(true)
			return
		}

		if (!agree) return

		const recaptchaToken = await getRecaptchaToken("REGISTER");
		if (!recaptchaToken) {
			toast.error('Failed to obtain reCAPTCHA token.')
		}
		setIsLoading(true)
		const newData = {
			...formData,
			recaptchaToken: recaptchaToken
		}
		const body = getFormData(newData)
		const res = await dispatch(registerFetch(body)).unwrap()
		if (res?.token) {
			setGoToHome(true)
		}
		setIsLoading(false)
	}

	const handleChange = (name, value) => {
		setFormData(prev => ({
			...prev, [name]: value
		}))
		if (name === "fullName") {
			const [firstName, lastName] = value.split(" ")
			setFormData(prev => ({
				...prev, firstName: firstName?.trim(), lastName: lastName?.trim()
			}))
		}
	}

	// const getProfessions = async () => {
	// 	try {
	// 		setIsProfessionsLoading(true)
	// 		const data = await dispatch(getAllProfessionsFetch()).unwrap()
	// 		setProfessions(data?.map(option => ({ label: option.name, value: option.id })) || [])
	// 		setIsProfessionsLoading(false)
	// 	} catch (error) {
	// 		setIsProfessionsLoading(false)
	// 		toast.error(error.message)
	// 	}
	// }
	const loadOptionsModerators = async (search, loadedOptions, { page }) => {
		const res = await dispatch(getAllProfessionsFetch(`page=${page}&search=${search}`)).unwrap();
		console.log(res)
		return {
			options: res.data?.map(option => ({ label: option.name, value: option.id })),
			hasMore: page != res?.pagination?.maxPages, // флаг, показывающий, есть ли еще данные
			additional: {
				page: page + 1, // увеличиваем номер страницы для следующего запроса
			},
		};
	};
	// useEffect(() => {
	// 	getProfessions()
	// }, [])
	if (goToHome) {
		return <Navigate to={'/setup/2FA'} />
	}
	return (
		<>
			<div className="wrapper">
				<main className="content">
					<section className="sing-up-section">
						<div className="sing-up-section__item sing-up-section__body">
							<form className="sing-up-section-form" autoComplete='off' onSubmit={handleSubmit}>
								<Link to={"/"} className="logo max-w-[130px]">
									{/* <img src={require("../../assets/img/logo.svg")} alt="" /> */}
									<img src={siteTheme === 'dark' ? logoDark : logo} alt="mediwise" />
								</Link>
								<div className="sing-up-section-form__body my-auto">
									<div className="sing-up-section-form__heading">
										<h2>Create a RCVIR account </h2>
										<p className="text-[13px]">RCVIR is completely free for everyone, but we truly appreciate any support you can provide.</p>
									</div>
									<div>
										<div className="upload-avatar">
											<div className="upload-avatar__preview !overflow-visible relative">
												{formData?.photo ?
													<>
														<button
															className='ico ico--xs btn--shadow-2 rounded-full btn--trash absolute -top-2 -right-2 z-10 btn--remove'
															type="button"
															onClick={(e) => { handleChange("photo", null) }}>
															<DeleteIcon />
														</button>
														<img src={getImageSrc(formData?.photo)} />
													</>
													:
													<span className="ico ico--xs">
														<UploadIcon />
													</span>
												}
											</div>
											<label className="upload-avatar__upload">
												<input type="file" hidden={true} accept='image/*' onChange={(e) => handleChange("photo", e.target?.files[0])} />
												<span className="btn btn--shadow btn--xs radius-5">
													{formData?.photo ? "Change" : "Add"} Profile Image
												</span>
											</label>
										</div>
										{isErrorShown && errors?.photo ?
											<p className='color-danger text--xs mt-1'>{errorMessages?.photo}</p>
											:
											null
										}
									</div>
									<div className="row g-24">
										<div className="col-md-6">
											<InputGroup
												wrapperClasses=' input--lg'
												label={'Full Name'}
												inputClasses='input--outline'
												placeholder={'Enter full name...'}
												value={formData?.fullName}
												error={isErrorShown && errors?.fullName}
												errorMessage={errorMessages?.fullName}
												onChange={(e) => handleChange("fullName", e.target.value)}
											/>
										</div>
										<div className="col-md-6">
											{/* <SelectGroup
												wrapperClasses='select--outline input--lg'
												label={'My Profession'}
												placeholder={'Select profession'}
												value={formData?.professionId}
												error={isErrorShown && errors?.professionId}
												errorMessage={errorMessages?.professionId}
												
												isLoading={isProfessionsLoading}
												onChange={({ value }) => handleChange("professionId", value)}
											/> */}
											<SelectGroupAsynk
												label={'My Profession'}
												placeholder={'Select profession'}
												wrapperClasses="input--md flex-auto select--outline"
												// defaultValue={formData?.professionId}
												onChange={({ value }) => handleChange("professionId", value)}
												fetchFunk={loadOptionsModerators}
											/>
										</div>
										<div className="col-md-6">
											<InputGroup
												wrapperClasses=' input--lg'
												label={'Email'}
												inputClasses='input--outline'
												placeholder={'Enter your email...'}
												value={formData?.email}
												error={isErrorShown && errors?.email}
												errorMessage={errorMessages?.email}
												onChange={(e) => handleChange("email", e.target.value)}
											/>
										</div>
										<div className="col-md-6">
											<InputPhoneCodeMask
												label={'Phone Number'}
												wrapperClasses=' input--lg'
												inputClasses='input--outline'
												placeholder={'Enter your phone...'}
												value={formData?.phoneNumber}
												error={isErrorShown && errors?.phoneNumber}
												errorMessage={errorMessages?.phoneNumber}
												onChange={(value) => handleChange("phoneNumber", value)}
											/>
										</div>
										<div className="col-md-6">
											<InputGroup
												wrapperClasses=' input--lg'
												label={'Password'}
												inputClasses='input--outline'
												type='password'
												placeholder={'Enter your password...'}
												autoComplete="new-password"
												value={formData?.password}
												error={isErrorShown && errors?.password}
												errorMessage={errorMessages.password}
												onChange={(e) => handleChange("password", e.target.value)}
											/>
										</div>
										<div className="col-md-6">
											<InputGroup
												wrapperClasses=' input--lg'
												label={'Confirm Password'}
												inputClasses='input--outline'
												type='password'
												placeholder={'Enter your password...'}
												autoComplete="new-password"
												value={formData?.confirmPassword}
												error={isErrorShown && errors?.confirmPassword}
												errorMessage={errorMessages.confirmPassword}
												onChange={(e) => handleChange("confirmPassword", e.target.value)}
											/>
										</div>
										<div className="col-12">
											<div className="flex flex-wrap justify-between">
												<CheckBoxGroup
													checked={agree}
													onChange={() => { setAgree(!agree) }}
												>
													<p className="check-text">
														I agree to RCVIR <Link to={"/terms"} className="color-danger">Terms of Use </Link> and <Link to={"/privacy"} className="color-danger">Privacy Policy</Link>
													</p>
												</CheckBoxGroup>
												{
													isErrorShown && !agree ?
														<p className='color-danger text--xs pl-7'>You must agree to the terms and conditions</p>
														:
														null
												}
											</div>
										</div>
									</div>
								</div>
								<div className="sing-up-section-form__bottom">
									<button
										type='submit'
										disabled={isLoading}
										className={`btn btn--primary btn--lg radius-5 w-full font-semibold ${isLoading ? 'disabled' : ''}`}>
										{isLoading ? <ButtonLoader /> : 'Sign Up'}
									</button>
									<div className="flex color-secondary items-center justify-center w-full text-[13px] gap-3">
										<span>Already have an account?</span>
										<button type='button' className="btn color-danger" onClick={handleLogin}>Login</button>
									</div>
								</div>
							</form>
						</div>
						<div className="sing-up-section__item sing-up-section__image" style={{
							backgroundImage: `url(${require('../../assets/img/sing-up-bg-image.png')})`
						}}>
							<Swiper className='sing-up-slider' pagination={{
								el: '.swiper-pagination'
							}} modules={[Pagination]}>
								<SwiperSlide className='sing-up-slider--item'>
									<div className="sing-up-slider--item__text">"RCVIR is a game-changer in upgrading my knowledge. Its user-friendly interface and interactive tools make learning enjoyable and accessible. The organized content and insightful feedback have boosted my confidence and deepened my understanding."</div>
									<div className="sing-up-slider--item__name">Leslie Alexander / Medical Student</div>
								</SwiperSlide>
								<div className="swiper-pagination swiper-pagination--white swiper-pagination--static text-start mt-[24px]"></div>
							</Swiper>
						</div>
					</section>
				</main>
			</div>
			{
				isLoginPopupOpen ?
					<LoginModal
						open={isLoginPopupOpen}
						handelClose={handleLogin}
						linkToRegister={false}
					/>
					:
					null
			}
		</>
	)
}

export default SignUp