import React, { useEffect } from 'react';
import CardArticle from '../../components/parts/Cards/CardArticle';
import CardCase from '../../components/parts/Cards/CardCase';
import { data } from "../../data"

import { ReactComponent as ChevronNext } from "../../assets/img/chevron-next.svg";
import { useSelector } from 'react-redux';
import PageLoader from '../../components/parts/PageLoader';
import { useDispatch } from 'react-redux';
import { getHomePageFetch } from '../../store/pages/pagesThunks';
import { getAuthorized, getMe, setShowLoginModal } from '../../store/auth/authSlice';
import { Link } from 'react-router-dom';
import { getDateToString, getImageSrc } from '../../helpers/utils';
import { getFiltersByName } from '../../store/filters/filtersThukns';
import { getFilteres } from '../../store/filters/filtersSlice';
import SidebarArticlesPosts from '../../components/parts/sidebarPosts/SidebarArticlesPosts';
import SirebarLatestCases from '../../components/parts/sidebarPosts/SirebarLatestCases';
import SidebarPopularQuizes from '../../components/parts/sidebarPosts/SidebarPopularQuizes';
import SidebarRecomendedCourses from '../../components/parts/sidebarPosts/SidebarRecomendedCourses';
import SidebarPopularForums from '../../components/parts/sidebarPosts/SidebarPopularForums';
import SubscriptionBigPart from '../../components/parts/SubscriptionBigPart';

const HomePage = () => {
    const dispatch = useDispatch()
    const me = useSelector(getMe)
    const { pageData, isLoading } = useSelector(state => state.pages)
    const authorized = useSelector(getAuthorized)
    const systems = useSelector(getFilteres);
    const explorationTips = ["How to use RCVIR platform?", "How properly set profile?", "Popular forums for new users"];
    const protectLink = (e) => {
        if (!authorized) {
            e.preventDefault()
            dispatch(setShowLoginModal(true))
        }
    }
    useEffect(() => {
        dispatch(getHomePageFetch())
        dispatch(getFiltersByName('industry'))
    }, [])
    if (isLoading) return <PageLoader />
    return (
        <main className="content">
            <section className="page-section">
                <div className="container">
                    <div className="page-section--row">
                        <div className="page-content">
                            <div className="section-cards">
                                {pageData && pageData?.caseOfTheMonth ? <div className="row gutters-cards">
                                    <div className="col-12">
                                        <div className="card card-post --card-row card-post--lg">
                                            <Link
                                                to={`/cases/${pageData?.caseOfTheMonth?.id}`}
                                                className="card-header post-image"
                                                onClick={protectLink}
                                            >
                                                <img src={getImageSrc(pageData?.caseOfTheMonth?.cover)} />
                                            </Link>
                                            <div className="card-body">
                                                <div className="card-body--item">
                                                    <div className="group-between">
                                                        <div className="pill-custom">
                                                            <span className="pill-text">Case of the Month</span>
                                                        </div>
                                                    </div>
                                                    <h2 className="heading post-title">{pageData?.caseOfTheMonth?.title}</h2>
                                                    {pageData?.caseOfTheMonth?.user && <div className="post-others">
                                                        <p className="text-[14px]">
                                                            by {" "}
                                                            <Link to={`/profile/${pageData?.caseOfTheMonth?.user?.id}`}>
                                                                <span className="color-danger">{`${pageData?.caseOfTheMonth?.user?.firstName}  ${pageData?.caseOfTheMonth?.user?.lastName}`}</span>
                                                            </Link>
                                                        </p>
                                                    </div>}
                                                </div>
                                                <div className="card-body--item">
                                                    <p className="post--desk">{pageData?.caseOfTheMonth?.description}</p>
                                                </div>
                                                <div className="card-body--item">
                                                    <div className="group group--sm">
                                                        <Link
                                                            to={`/cases/${pageData?.caseOfTheMonth?.id}`}
                                                            className="btn btn--primary btn--md radius-5"
                                                            onClick={protectLink}
                                                        >
                                                            <span>View Case</span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> : null}
                                {pageData && pageData?.cases?.length ? <div className="posts-list">
                                    <div className="row gutters-cards">
                                        {
                                            pageData.cases.map(card => (
                                                <div key={card.id} className="col-lg-4 col-md-6 col-12">
                                                    <CardCase data={card} authorized={authorized} />
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div> : null}
                                <SubscriptionBigPart/>
                                {pageData && pageData?.industryUpdates?.length ? <div className="posts-list">
                                    <div className="heading-row">
                                        <div className="heading-block">
                                            <h2 className="heading">Industry Updates</h2>
                                        </div>
                                    </div>
                                    <div className="row gutters-cards">

                                        {pageData?.industryUpdates?.map(card => (
                                            <div key={card.id} className="col-lg-4 col-md-6 col-12">
                                                <CardArticle data={card} authorized={authorized} />
                                            </div>
                                        ))}

                                    </div>
                                    <Link to={'/articles'} className="btn max-w-max color-danger gap-[8px]">
                                        <span className="text-[13px] font-[500]">View more articles</span>
                                        <span className="ico">
                                            <ChevronNext />
                                        </span>
                                    </Link>
                                </div> :
                                    null
                                }
                            </div>
                            {pageData && pageData?.digitalHealth?.length ? <div className="section-cards">
                                <div className="posts-list">
                                    <div className="heading-row">
                                        <div className="heading-block">
                                            <h2 className="heading">Digital Health</h2>
                                        </div>
                                    </div>
                                    <div className="row gutters-cards">
                                        {

                                            pageData?.digitalHealth?.map(card => (
                                                <div key={card.id} className="col-12">
                                                    <CardArticle data={card} isList={true} authorized={authorized} />
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <Link to={'/articles'} className="btn max-w-max color-danger gap-[8px]">
                                        <span className="text-[13px] font-[500]">View more articles</span>
                                        <span className="ico">
                                            <ChevronNext />
                                        </span>
                                    </Link>
                                </div>
                            </div> : null}
                            <div className="section-cards">
                                {pageData && pageData?.genomics?.length ?
                                    <div className="posts-list">
                                        <div className="heading-row">
                                            <div className="heading-block">
                                                <h2 className="heading">Genomics</h2>
                                            </div>
                                        </div>
                                        <div className="row gutters-cards">
                                            {

                                                pageData.genomics.map(card => (
                                                    <div key={card.id} className="col-12">
                                                        <CardArticle data={card} isList={true} authorized={authorized} />
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <Link to={'/articles'} className="btn max-w-max color-danger gap-[8px]">
                                            <span className="text-[13px] font-[500]">View more articles</span>
                                            <span className="ico">
                                            </span>
                                        </Link>
                                    </div>
                                    : null}
                                <div className="row gutters-cards">
                                    <div className="col-12">
                                        <div className="banner after-005" style={{
                                            background: "var(--bunner-bg-2)"
                                        }}>
                                            <div className="banner-row">
                                                <div className="banner-content">
                                                    <div className="heading-block">
                                                        <h3 className="heading text-[#fff]">
                                                            Embark on a Learning Journey:
                                                            Explore Our Diverse Courses Now
                                                        </h3>
                                                        <p className="heading-desk text-[#fff]">
                                                            Discover a world of knowledge
                                                            with our extensive range of courses
                                                        </p>
                                                    </div>
                                                    <div className="group group--sm">
                                                        <Link to={'/courses'} className="btn btn--gradient-primary btn--md rounded-full">
                                                            <span className="text-[14px] font-[500]">
                                                                Explore Courses
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="banner-img banner-img--shadow">
                                                    <img src={require("../../assets/img/banner-img-2.png")} alt='banner' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {pageData && pageData?.mentalHealth?.length ?
                                    <div className="posts-list">
                                        <div className="heading-row">
                                            <div className="heading-block">
                                                <h2 className="heading">Mental Health</h2>
                                            </div>
                                        </div>
                                        <div className="row gutters-cards">
                                            {

                                                pageData.mentalHealth.map(card => (
                                                    <div key={card.id} className="col-12">
                                                        <CardArticle data={card} isList={true} authorized={authorized} />
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <Link to={'/articles'} className="btn max-w-max color-danger gap-[8px]">
                                            <span className="text-[13px] font-[500]">View more articles</span>
                                            <span className="ico">
                                                <ChevronNext />
                                            </span>
                                        </Link>
                                    </div>
                                    : null}
                            </div>
                            {pageData && pageData?.surgery?.length ?
                                <div className="section-cards">
                                    <div className="posts-list">
                                        <div className="heading-row">
                                            <div className="heading-block">
                                                <h2 className="heading">Surgery</h2>
                                            </div>
                                        </div>
                                        <div className="row gutters-cards">
                                            {
                                                pageData?.surgery.map(card => (
                                                    <div key={card.id} className="col-12">
                                                        <CardArticle data={card} isList={true} authorized={authorized} />
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <Link to={'/articles'} className="btn max-w-max color-danger gap-[8px]">
                                            <span className="text-[13px] font-[500]">View more articles</span>
                                            <span className="ico">
                                                <ChevronNext />
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                                : null}
                        </div>
                        <div className="page-sidebar" style={{ position: 'static' }}>
                            <aside className="sidebar post-sidebar">
                                <div className="sidebar-body">
                                    {pageData && pageData?.recentArticles?.length ? 
                                    <SidebarArticlesPosts 
                                        posts={pageData?.recentArticles} 
                                        title={'Recent Articles'}
                                        postImageBig={true}
                                    /> 
                                    : null}

                                    {systems && systems?.length ?<div className="post-widget">
                                        <div className="post-widget--header">
                                            <div className="heading-post-row">
                                                <span className="ico ico--xs">
                                                    <img src={require("../../assets/img/ico-recomend-topic.svg").default} alt='topics' />
                                                </span>
                                                <p className="heading-title-post text-def-2-c">
                                                    Recommended Topics
                                                </p>
                                            </div>
                                        </div>
                                        <div className="post-widget--body flex flex-col">
                                            <ul className="keywords-list">
                                                {
                                                    systems.slice(0, 9).map((topic, index) => (
                                                        <li key={index}>
                                                            <Link to={`/articles?industry=${String(topic.name).replace(' ','+')}`} className="btn btn--light btn--sm-2 btn-keyword">
                                                                <span>{topic.name}</span>
                                                            </Link>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                            <Link to={'/articles'} className="btn max-w-max">
                                                <span className="color-danger text-[13px] font-[500]">
                                                    See more topics
                                                </span>
                                            </Link>
                                        </div>
                                    </div> : null}

                                    {pageData && pageData?.recentCases?.length ? 
                                        <SirebarLatestCases 
                                            cases={pageData?.recentCases} 
                                            title={'Recent Cases'} 
                                            postImageBig={true}
                                        /> 
                                    : null}

                                    <div className="post-widget">
                                        <div className="post-widget--body flex flex-col">
                                            <div className="card card-custom card-md">
                                                <div className="card-header">
                                                    <h5 className="heading">Exploration Tips</h5>
                                                </div>
                                                <div className="card-body">
                                                    <ul className="flex flex-col gap-[10px]">
                                                        {
                                                            explorationTips && explorationTips?.length ?
                                                                explorationTips.map((tip, index) => (

                                                                    <li key={index}>
                                                                        <Link to={'/faq'} className="btn btn--sm-2 btn-keyword before-point text-left justify-start btn--def">
                                                                            <span>{tip}</span>
                                                                        </Link>
                                                                    </li>
                                                                ))
                                                                :
                                                                null
                                                        }

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {pageData && pageData?.popularForums?.length ? 
                                        <SidebarPopularForums 
                                            title={'Popular Forums'} 
                                            forums={pageData?.popularForums}
                                            postImageBig={true}
                                        /> 
                                    : null}

                                    {pageData && pageData?.recomendedCourses?.length ? 
                                        <SidebarRecomendedCourses 
                                            courses={pageData?.recomendedCourses}
                                            title={'Recommended Courses'}
                                            postImageBig={true}
                                        /> 
                                    : null}

                                    {pageData && pageData?.recentQuizzes?.length ? 
                                        <SidebarPopularQuizes 
                                            quizes={pageData?.recentQuizzes} 
                                            title={'Recent Quizzes'}
                                            postImageBig={true}
                                        /> 
                                    : null}

                                </div>
                            </aside>
                        </div>
                    </div>

                </div>
            </section>
        </main>
    )
}

export default HomePage