import React, { useState } from 'react';
import {
    DndContext,
    DragEndEvent,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
    arrayMove,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ReactComponent as DotsIcon } from '../../assets/img/ico-dots-2.svg';
import { ReactComponent as UploadIcon } from "../../assets/img/ico-image-upload.svg"
import { ReactComponent as TrashIcon } from "../../assets/img/ico-trash.svg"
import { getImageSrc } from '../../helpers/utils';
const DraggableItem = ({ id, data, removeHandler, inputsHandler, markHandler }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id });

    const style = {
        transform: CSS.Translate.toString(transform),
        transition,
        zIndex: isDragging ? 1000 : 'auto',
        boxShadow: isDragging ? '0 4px 10px rgba(0, 0, 0, 0.3)' : 'none',
        touchAction: 'none'
    };

    return (
        <div
            ref={setNodeRef}
            className="actions-group actions-group--mobile"
            style={style}
        >
            <span {...listeners} style={{ cursor: 'grab' }} className="btn btn--secondary-2 btn--md px-[2px] cursor-move">
                <span className="ico">
                    <DotsIcon />
                </span>
            </span>

            <button
                type='button'
                onClick={() => { markHandler(parseInt(id)) }}
                className="btn btn--secondary-2 btn--md mark-as-correct-btn">

                {data.correct_answer ?
                    <>
                        <span className="ico mr-[8px]">
                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.6875 8.375L5.75 11.4375L12.75 4.4375" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </span>
                        <span 
                            className="text-[13px]" 
                            // style={{color:'#292929'}}
                        >Correct</span>
                    </>
                    :
                    <span className="text-[13px]">Mark as correct</span>
                }

            </button>

            <div className="input-wrapper input--md flex-auto">
                <input className="input input--outline" type="text" placeholder="Enter option here..." value={data.answer_text} onChange={({ target }) => inputsHandler('answer_text', target.value, data.order)} />
            </div>
            {data?.answer_image ? <div className="image-preview-group">
                <img src={getImageSrc(data?.answer_image)} alt="" />
                <button
                    type='button'
                    onClick={({ target }) => inputsHandler('answer_image', null, data.order)}
                    className="btn color-danger">
                    <TrashIcon />
                </button>
            </div>
                :
                <label className="btn btn--square btn--md">
                    <input type="file" accept='image/*' onChange={({ target }) => inputsHandler('answer_image', target.files[0], data.order)} hidden />
                    <UploadIcon />
                </label>}

            <button type='button' onClick={() => removeHandler(data.order)} className="btn btn--secondary-2 btn--square btn--md">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 8.5L16 16.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M16 8.5L8 16.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </button>
            <div {...attributes} style={{ display: 'none' }} />
        </div>
    );
};

const SortQuizAnsvers = ({ items, setItems, errors, setErrors }) => {
    
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );
    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (over && active.id !== over.id) {

            const oldIndex = items.answers.findIndex(item => item.order === parseInt(active.id));
            const newIndex = items.answers.findIndex(item => item.order === parseInt(over.id));
            setItems((prev) => {
                const updatedAnswers = arrayMove(prev.answers, oldIndex, newIndex).map((item, index) => ({
                    ...item,
                    order: index,
                }));

                return { ...prev, answers: updatedAnswers };
            });
        }
    };
    
    const editAnswerHandler = (name, value, index) => {
        if(name === `answers_${index}_answer_text`) {
            setErrors(prev => {
                return {
                    ...prev,
                    [`answers_${index}_answer_text`]:  value?.trim().length > 0 ? false : 'Answer text is required'
                }
            
            })
        }
        setItems((prev) => ({
            ...prev,
            answers: prev.answers.map(item => {
                if (item.order === +index) {
                    
                    return {
                        ...item,
                        [name]: value
                    }

                } else {
                    return item
                }
            })
        }))
    }
    const removeAnswerHandler = (index) => {
        const filteredAnswers = items.answers.filter(item => item.order !== index)
        setItems(prev => ({
            ...prev,
            answers: filteredAnswers.map((answer, idx) => ({
                ...answer,
                order: idx
            }))
        }))
    }
    const markAsCorrect = (index) => {
        setErrors(prev => ({
            ...prev,
            correct_answer :  false
        }))
        setItems(prev => ({
            ...prev,
            answers: prev.answers.map(item => {
                if (item.order === +index) {
                    return {
                        ...item,
                        correct_answer: true
                    }

                } else {
                    return { ...item, correct_answer: false }
                }
            })
        }))
    }
    return (
        <div className="questions-flow mb-[16px]">
            <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
                <SortableContext items={items.answers.map(item => item.order.toString())} >
                    {items.answers.map((item, index) => (
                        <React.Fragment key={item.order}>
                            <DraggableItem  id={item.order.toString()} data={item} removeHandler={removeAnswerHandler} inputsHandler={editAnswerHandler} markHandler={markAsCorrect} />
                            {errors[`answers_${index}_answer_text`] && <p className='color-danger text--xs'>{errors[`answers_${index}_answer_text`]}</p>}
                        </React.Fragment>
                    ))}
                </SortableContext>
            </DndContext>
            {errors.correct_answer && <p className='color-danger text--xs mt-1'>{errors.correct_answer}</p>}
        </div>
    );
};

export default SortQuizAnsvers;
