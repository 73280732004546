import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { toast } from 'react-toastify';


export const getAllProfessionsFetch = createAsyncThunk(
    '/get-all-professions/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.professions.getAllProfessions(payload);
            if (res.success) {
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            
            toast.error("Something went wrong")
            return rejectWithValue(err?.response.data);
        }
    },
);
