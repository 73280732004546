import React, { useEffect, useMemo, useState } from 'react'
import Pagination from '../../components/parts/Pagination'
import SidebarFilters from '../../components/parts/SidebarFilters'
import InputSearch from '../../components/UI/formElements/InputSearch'
import SelectGroup from '../../components/UI/formElements/SelectGroup'
import CardForum from '../../components/parts/Cards/CardForum'

import { ReactComponent as PlusIcon } from "../../assets/img/ico-plus-2.svg"
import SwitchViewType from '../../components/parts/SwitchViewType'

import { data } from "../../data"
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { filterOptions } from '../../helpers/constants'
import { setClearForum } from '../../store/forums/forumsSlice'
import { setShowLoginModal } from '../../store/auth/authSlice'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { getFilteres } from '../../store/filters/filtersSlice'
import { getFiltersByName } from '../../store/filters/filtersThukns'
import useDebounce from '../../hooks/useDebounce'
import { getAllForumsFeth } from '../../store/forums/forumsThunk'
import PageLoader from '../../components/parts/PageLoader'



// export const filters = [
//   {
//     title: "Filter by Categories",
//     checkboxOptions: [
//       { value: 'quiz', label: 'Quiz' },
//       { value: 'breast', label: 'Breast' },
//       { value: 'cardiac', label: 'Cardiac' },
//       { value: 'flashcards', label: 'Flashcards' },
//       { value: 'central-nervous-system', label: 'Central Nervous System' },
//       { value: 'chest', label: 'Chest' },
//       { value: 'forensic', label: 'Forensic' },
//       { value: 'gastrointestinal', label: 'Gastrointestinal' },
//       { value: 'gynaecology', label: 'Gynaecology' },
//       { value: 'haematology', label: 'Haematology' },
//       { value: 'head-neck', label: 'Head & Neck' },
//       { value: 'hepatobiliary', label: 'Hepatobiliary' }
//     ]
//   },
// ]

export const tips = [
    { title: "How to use forum?", id: 1 },
    { title: "How to create forum?", id: 2 },
    { title: "How to share forum?", id: 3 }
]


const Forums = () => {
    const { authorized, showLoginModal } = useSelector((state) => state.auth)
    const { forumsAll, isLoading, pagination } = useSelector(state => state.forums)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [isList, setIsList] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const systems = useSelector(getFilteres);

    // const [params, setParams] = useState({
    //   sort: 'latest',
    //   category: [],
    //   search: ''
    // })
    const formatOptionsQuery = (value) => {
        return value?.length
            ? value.split(',').filter(Boolean) || []
            : []
    }
    const params = useMemo(() => ({
        sort: searchParams.get('sort') || 'latest',
        category: formatOptionsQuery(searchParams.get('category')) || [],
        search: searchParams.get("search") || ""
    }), [searchParams])

    const filters = [
        {
            title: "Filter by Categories",
            checkboxOptions: systems
        },
    ]

    const handleFilterChange = (options) => {
        setSearchParams({
            ...params,
            category: params.category.includes(options.name)
                ? params.category.filter((cat) => cat !== options.name).join(',')
                : [...params.category, options.name].join(','),
        });
    }
    const debouncedSearchChange = useDebounce((value) => {
        setSearchParams({
            ...params,
            search: value
        });
    }, 500);
    const handleSearchChange = (e) => {
        const { value } = e.target;
        debouncedSearchChange(value);
    };

    useEffect(() => {
        const fetchData = async () => {

            const res = await dispatch(getAllForumsFeth(searchParams.toString())).unwrap()
            if (res) {
                window.scrollTo(0, 0)
            }
        }
        fetchData()
    }, [searchParams])

    const handleSortChange = (option) => {
        setSearchParams({
            ...params,
            sort: option.value,
        });
    }

    useEffect(() => {
        dispatch(getFiltersByName('system'))
    }, [dispatch])
    return (
        <main className="content">
            <div className="container">
                <section className="section-filters">
                    <SidebarFilters filters={filters} defaultCheked={params?.category} tips={tips} onChange={handleFilterChange} />
                    <div className="section-filters__body">
                        <div className="page-heading mb-[30px]">
                            <div className="page-heading__item">
                                <h3 className="mr-[24px]">Forums</h3>
                                <SwitchViewType onChange={(checked) => setIsList(checked)} defaultValue={isList} />
                            </div>
                            <div className="page-heading__item">
                                <InputSearch className='input--sm w-[250px]' defaultValue={params.search} onChange={handleSearchChange} />
                                <SelectGroup
                                    wrapperClasses={'input--sm'}
                                    options={filterOptions}
                                    defaultValue={filterOptions[0]}
                                    onChange={handleSortChange}
                                />
                                <button
                                    type='button'
                                    onClick={() => {
                                        if (authorized) {
                                            dispatch(setClearForum())
                                            navigate('/forum/general')
                                        } else {
                                            dispatch(setShowLoginModal(true))
                                        }
                                    }}
                                    className="btn btn--primary btn--md gap--xs radius-5">
                                    <span className="ico ico-22 mr-1">
                                        <PlusIcon />
                                    </span>
                                    <span className="info">Create Forums</span>
                                </button>
                            </div>
                        </div>
                        <div className="row gutters-cards flex-auto">
                            {
                                forumsAll && forumsAll.length && !isLoading ?
                                    forumsAll.map(card => (
                                        <div key={card.id} className={!isList ? "col-sm-6 col-lg-4 col-xl-3" : "col-12"}>
                                            <CardForum data={card} isList={isList} authorized={authorized} />
                                        </div>
                                    ))
                                    : isLoading ?
                                        <PageLoader /> :
                                        <h2>Page is empty</h2>

                            }
                        </div>
                        <Pagination {...pagination} params={params} />
                    </div>
                </section>
            </div>
        </main>
    )
}

export default Forums