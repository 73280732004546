import { useEffect, useRef } from "react";

export const useClickOutside = (callback, refNode) => {
    // useEffect(() => {
  
    //     const listener = (event) => {
    //         console.log(ref.current)
    //         if (!ref.current || ref.current.contains(event.target)) {
    //             return;
    //         }
    //         callback(event);
    //     };
    //     document.addEventListener('mousedown', listener);
    //     document.addEventListener('touchstart', listener);
    //     return () => {
    //         document.removeEventListener('mousedown', listener);
    //         document.removeEventListener('touchstart', listener);
    //     };
    // },[ref, callback]);
    const domNode = useRef();

    useEffect(() => {
        const eventHandler = (event) => {
            if (domNode.current && !domNode.current.contains(event.target)) {
                if (refNode && refNode.current && refNode.current.contains(event.target)) {
                    return;
                }

                callback();
            }
        };

        document.addEventListener("mousedown", eventHandler);

        return () => {
            document.removeEventListener("mousedown", eventHandler);
        };
    }, [callback, refNode]);

    return domNode;
};

