import React, { useEffect, useMemo, useState } from 'react'
import Pagination from '../../components/parts/Pagination'
import SidebarFilters from '../../components/parts/SidebarFilters'
import InputSearch from '../../components/UI/formElements/InputSearch'
import SelectGroup from '../../components/UI/formElements/SelectGroup'
import CardCase from '../../components/parts/Cards/CardCase'

import { ReactComponent as PlusIcon } from "../../assets/img/ico-plus-2.svg"
import SwitchViewType from '../../components/parts/SwitchViewType'

import { data } from "../../data"
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { filterOptions } from '../../helpers/constants'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { getFiltersByName, getFiltersDiagnosisFeth } from '../../store/filters/filtersThukns'
import { getFilterDiagnosis, getFilteres } from '../../store/filters/filtersSlice'
import { getAllCassesFeth } from '../../store/cases/casesThunk'
import PageLoader from '../../components/parts/PageLoader'
import useDebounce from '../../hooks/useDebounce'
import { setShowLoginModal } from '../../store/auth/authSlice'
import { setClearCase } from '../../store/cases/casesSlice'

export const tips = [
	{ title: "How to use cases?", id: 1 },
	{ title: "How to create new case?", id: 2 },
	{ title: "How to share cases?", id: 3 }
]


const Cases = () => {
	const { authorized, showLoginModal } = useSelector((state) => state.auth)
	const { casesAll, isLoading, pagination } = useSelector(state => state.cases)
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [searchParams, setSearchParams] = useSearchParams()
	const systems = useSelector(getFilteres);
	const diagnosisFilters = useSelector(getFilterDiagnosis);
	const [isList, setIsList] = useState(false)
	const formatOptionsQuery = (value) => {
		return value?.length
			? value.split(',').filter(Boolean) || []
			: []
	}
	const params = useMemo(() => ({
		sort: searchParams.get('sort') || 'latest',
		system: formatOptionsQuery(searchParams.get('system')) || [],
		diagnosis: formatOptionsQuery(searchParams.get('diagnosis')) || [],
		search: searchParams.get("search") || ""
	}), [searchParams])
	
	const filters = [
		{
			title: "Filter by Diagnosis",
			type: 'diagnosis',
			checkboxOptions: diagnosisFilters
		},
		{
			title: "Filter by Categories",
			type: 'system',
			checkboxOptions: systems
		},
	]
	const handleFilterChange = (options, type) => {
		setSearchParams(prev => {
			const nePparams = params[type].includes(options.name)
				? params[type].filter((cat) => cat !== options.name).join(',')
				: [...params[type], options.name].join(',');
			prev.set([type], nePparams)
			return prev
		});
	}

	const handleSortChange = (option) => {
		setSearchParams({
			...params,
			sort: option.value,
		});
	}
	const debouncedSearchChange = useDebounce((value) => {
		setSearchParams({
			...params,
			search: value
		});
	}, 500);
	const handleSearchChange = (e) => {
		const { value } = e.target;
		debouncedSearchChange(value);
	};
	useEffect(() => {
		const fetchData = async () => {
			const res = await dispatch(getAllCassesFeth(searchParams.toString())).unwrap()
			if (res) {
				window.scrollTo(0, 0)
			}
		}
		fetchData()
	}, [searchParams])
	
	useEffect(() => {
		dispatch(getFiltersByName('system'))
		dispatch(getFiltersDiagnosisFeth())
	}, [dispatch])

	return (
		<main className="content">
			<div className="container">
				<section className="section-filters">
					<SidebarFilters filters={filters} tips={tips} defaultCheked={[...params?.system, ...params.diagnosis]} onChange={handleFilterChange} />
					<div className="section-filters__body">
						<div className="page-heading mb-[30px]">
							<div className="page-heading__item">
								<h3 className="mr-[24px]">Cases</h3>
								<SwitchViewType onChange={(checked) => setIsList(checked)} />
							</div>
							<div className="page-heading__item">
								<InputSearch className='input--sm w-[250px]' defaultValue={params.search} onChange={handleSearchChange} />
								<SelectGroup
									wrapperClasses={'input--sm'}
									options={filterOptions}
									defaultValue={filterOptions[0]}
									onChange={handleSortChange}
								/>
								<button
									onClick={
										() => {
											if (authorized) {
												dispatch(setClearCase())
												navigate('/case-create')
											} else {
												dispatch(setShowLoginModal(true))
											}
										}}
									className="btn btn--primary btn--md gap--xs radius-5">
									<span className="ico ico-22 mr-1">
										<PlusIcon />
									</span>
									<span className="info">Create Case</span>
								</button>
							</div>
						</div>
						<div className="row gutters-cards flex-auto">
							{
								casesAll && casesAll.length ?
									casesAll.map(card => (
										<div key={card.id} className={!isList ? "col-sm-6 col-lg-4 col-xl-3" : "col-12"} >
											<CardCase data={card} isList={isList} authorized={authorized} />
										</div>
									))
									: isLoading ?
										<PageLoader /> :
										<h2>Page is empty</h2>
							}
						</div>
						<Pagination {...pagination} params={params} />

					</div>
				</section>
			</div>
		</main>
	)
}

export default Cases