import React from 'react'
import ModalTemplate from './ModalTemplate'
import { Link, useParams } from 'react-router-dom'

const TimerEndModal = ({ open, handelClose, blockCloseBg, restartQuize }) => {
    return (
        <ModalTemplate open={open} handelClose={handelClose} showHeader={false} blockCloseBg={blockCloseBg} modalSize='modal__dialog--410'>
            <div className="modal__body items-center pt-0">
                <div className="successfull-paymant">
                    <div className="successfull-paymant__content flex-auto">
                        <img src={require("../../assets/img/Timer.png")} alt="" />
                        <h2 className="successfull-paymant__title mb-[16px]">Your time is up</h2>
                        <p className="successfull-paymant__subtitle mb-[24px]">Thank you for choosing RCVIR</p>
                        <div className="flex items-center gap-4 flex-wrap">
                            <Link to={'/'} className="btn btn--primary btn--lg radius-5 flex-auto">Back to Home</Link>
                            <button type='button' onClick={restartQuize} className="flex-auto btn btn--shadow btn--md radius-5 gap--xs">
                                <span className="ico">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17.864 4.25244V7.49377H14.6235H17.864" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M17.4413 7.49359C16.1973 5.21384 13.7792 3.6665 10.9998 3.6665C6.95 3.6665 3.6665 6.95 3.6665 10.9998C3.6665 15.0497 6.95 18.3332 10.9998 18.3332C15.0497 18.3332 18.3332 15.0497 18.3332 10.9998" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </span>
                                <span className="info">Restart Quiz</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ModalTemplate>
    )
}

export default TimerEndModal
