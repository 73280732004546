import { useMemo } from 'react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import CardThread from '../../components/parts/Cards/CardThread'
import PageLoader from '../../components/parts/PageLoader'
import Pagination from '../../components/parts/Pagination'
import SidebarFilters from '../../components/parts/SidebarFilters'
import SwitchViewType from '../../components/parts/SwitchViewType'
import SelectGroup from '../../components/UI/formElements/SelectGroup'
import { filterOptions } from '../../helpers/constants'
import { getFilteres } from '../../store/filters/filtersSlice'
import { getFiltersByName } from '../../store/filters/filtersThukns'
import { getAllForumsFeth, getAllThreadsFeth, getFavoriteThreadsFetch } from '../../store/forums/forumsThunk'
import { Favoritelinks } from './Favorites'
import CardForum from '../../components/parts/Cards/CardForum'

const FavoriteForums = () => {
    const { authorized } = useSelector((state) => state.auth)
    const { forumsAll, isLoading, pagination } = useSelector(state => state.forums)
    const [isList, setIsList] = useState(false)
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    const systems = useSelector(getFilteres);

    const formatOptionsQuery = (value) => {
        return value?.length
            ? value.split(',').filter(Boolean) || []
            : []
    }

    const params = useMemo(() => ({
        sort: searchParams.get('sort') || 'latest',
        category: formatOptionsQuery(searchParams.get('category')) || [],
        search: searchParams.get("search") || ""
    }), [searchParams])

    const filters = [
        {
            title: "Filter by Industries",
            checkboxOptions: systems
        },
    ]



    const handleQueryChange = (name, value) => {
        setSearchParams(prev => {
            if (value) {
                prev.set(name, value)
            } else if (prev.get(name)) {
                prev.delete(name)
            }
            return prev
        })
    }

    const handleFilterChange = (options) => {
        const value = params.category.includes(options.name)
            ? params.category.filter((cat) => cat !== options.name).join(',')
            : [...params.category, options.name].join(',')
        handleQueryChange("category", value)
    }

    useEffect(() => {
        const fetchData = async () => {
            const res = await dispatch(getAllForumsFeth(`favorite=true&${searchParams.toString()}`)).unwrap()
            if (res) {
                window.scrollTo(0, 0)
            }
        }
        fetchData()
    }, [searchParams])

    const handleSortChange = (option) => {
        handleQueryChange("sort", option.value)
    }

    useEffect(() => {
        dispatch(getFiltersByName('system'))
    }, [dispatch])

    return (
        <main className="content">
            <div className="container">
                <section className="section-filters">
                    <SidebarFilters filters={filters} links={Favoritelinks} defaultCheked={params?.category} onChange={handleFilterChange} />
                    <div className="section-filters__body">
                        <div className="page-heading mb-[30px]">
                            <div className="page-heading__item">
                                <h3 className="mr-[24px]">Favorites</h3>
                                <SwitchViewType onChange={(checked) => setIsList(checked)} />
                            </div>
                            <div className="page-heading__item">
                                <SelectGroup
                                    wrapperClasses={'input--sm'}
                                    options={filterOptions}
                                    defaultValue={filterOptions[0]}
                                    onChange={handleSortChange}
                                />
                            </div>
                        </div>
                        <div className="row gutters-cards flex-auto">
                            {
                                forumsAll && forumsAll.length && !isLoading ?
                                    forumsAll.map(card => (
                                        <div key={card.id} className={!isList ? "col-sm-6 col-lg-4 col-xl-3" : "col-12"}>
                                            <CardForum data={card} isList={isList} authorized={authorized} />
                                        </div>
                                    ))
                                    : isLoading ?
                                        <PageLoader /> :
                                        <h2>Page is empty</h2>

                            }
                        </div>
                        <Pagination {...pagination} params={params} />
                    </div>
                </section>
            </div >
        </main >
    )
}

export default FavoriteForums
