const professionsBase = 'api/professions';

export const professions = (instance) => ({
    async getAllProfessions(payload) {
        try {
            const { data } = await instance.get(`${professionsBase}?${payload}`, );
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },

});