import React, { useState } from 'react'
import ModalTemplate from './ModalTemplate'
import InputGroup from '../UI/formElements/InputGroup'
import ButtonLoader from '../UI/ButtonLoader'
import logo from "../../assets/img/logo.svg"
import logoDark from "../../assets/img/logo-footer.svg"
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { login2faFetch, verifyFaFetch } from '../../store/auth/authThunks'
import { setToken } from '../../store/auth/authSlice'

const Modal2FA = ({ open, handelClose }) => {
    const dispatch = useDispatch()
    const [code2fa, setCode2fa] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const { siteTheme, tempToken } = useSelector(state => state.auth)

    const handleSubmit = async () => {
        setIsLoading(true)
        const data = {
            "tempToken": tempToken,
            "code": +code2fa
        }
        console.log(data)
        const res = await dispatch(login2faFetch(data)).unwrap()
        if (res?.token) {
            localStorage.setItem("authToken", res?.token)
            dispatch(setToken(res?.token))
            handelClose()
        }
        setCode2fa('')
        setIsLoading(false)
    }
    return (
        <ModalTemplate open={open} handelClose={handelClose}>
            <div className="modal__body items-center">
                <div className="btn">
                    {/* <img src={require("../../assets/img/logo-modal-darck.png")} alt="" /> */}
                    <img src={siteTheme === 'dark' ? logoDark : logo} alt="mediwise" />
                </div>
                <div className="modal__body-heading text-center">
                    <h2>Welcome Back</h2>
                    <p>Please enter your Google authenticator code</p>
                </div>
                <div className="w-full">
                    <div className="row g-16">
                        <div className="col-12">
                            <InputGroup
                                wrapperClasses=' input--lg'
                                type='number'
                                inputClasses='input--outline'
                                placeholder={'Enter your code...'}
                                value={code2fa}
                                onChange={(e) => setCode2fa(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex flex-col w-full gap-[24px]">
                    <button type='button' disabled={isLoading} className={`btn btn--primary btn--lg radius-5 w-full ${isLoading ? 'disabled' : ''}`} onClick={handleSubmit} >
                        {isLoading ? <ButtonLoader /> : 'Confirm'}
                    </button>
                </div>
            </div>
        </ModalTemplate>
    )
}

export default Modal2FA
