import React, { useEffect, useRef, useState } from 'react'
import ModalTemplate from './ModalTemplate'
import Chart from 'chart.js/auto';
import { toast } from 'react-toastify';
import SelectGroup from '../UI/formElements/SelectGroup';
import { createChartFeth, getAllChartsFeth } from '../../store/charts/chartsThuks';
import { useDispatch } from 'react-redux';
import useDebounce from '../../hooks/useDebounce';
import { useSelector } from 'react-redux';
import SelectGroupRow from '../UI/formElements/SelectGroupRow';
import SelectGroupAsynk from '../UI/formElements/SelectGroupAsynk';
const alphabet = [
    'A', 'B', 'C', 'D', 'E', 'F', 'G',
    'H', 'I', 'J', 'K', 'L', 'M', 'N',
    'O', 'P', 'Q', 'R', 'S', 'T', 'U',
    'V', 'W', 'X', 'Y', 'Z'
]
const cols = new Array(36).fill('');
const DEFAULTCOLOR = '#FFE47F'
const LIMIT = 10
const AddChartModal = ({ open, handleClose, initialData, onInsert }) => {
    const [labels, setLabels] = useState(Array(5).fill('Data'));
    const [chartTitle, setChartTitle] = useState('');
    const dispatch = useDispatch()
    const [datasets, setDatasets] = useState([
        {
            label: 'series 1',
            data: Array(5).fill(22),
            backgroundColor: Array(5).fill(DEFAULTCOLOR), // default color
        }
    ]);
    const { isLoading } = useSelector(state => state.charts)
    const [chartType, setChartType] = useState('bar');
    const [yAxisMin, setYAxisMin] = useState(0);
    const [yAxisMax, setYAxisMax] = useState(30);
    const canvasRef = useRef(null);
    const chartInstanceRef = useRef(null);
    const [selectedChart, setSelectedChart] = useState(null)
    
    const loadOptions = async (search, loadedOptions, { page }) => {
        const res = await  dispatch(getAllChartsFeth(`page=${page}&limit=${LIMIT}&search=${search}`)).unwrap();
        
        return {
          options: res.data?.map(chatr => ({ label: chatr.title, value: chatr.data })),
          hasMore: page != res?.pagination?.maxPages, // флаг, показывающий, есть ли еще данные
          additional: {
            page: page + 1, // увеличиваем номер страницы для следующего запроса
          },
        };
      };
    useEffect(() => {
        if (initialData) {
            setLabels(initialData.labels)
            setDatasets(initialData.datasets)
            setChartType(initialData.type)
            setYAxisMax(initialData.yAxisMax)
            setYAxisMin(initialData.yAxisMin)
            setChartTitle(initialData.title)
        }
    }, [initialData])
    useEffect(() => {
        if (selectedChart) {
            setLabels(selectedChart.labels)
            setDatasets(selectedChart.datasets)
            setChartType(selectedChart.type)
            setYAxisMax(selectedChart.yAxisMax)
            setYAxisMin(selectedChart.yAxisMin)
            setChartTitle(selectedChart.title)
        }
    }, [selectedChart])
    
    useEffect(() => {
        if (canvasRef.current) {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }
            chartInstanceRef.current = new Chart(canvasRef.current, {
                type: chartType,
                data: {
                    labels: labels,
                    datasets: datasets.map(dataset => ({
                        ...dataset,
                        borderColor: dataset.backgroundColor,
                        borderWidth: 1,
                    })),
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false,
                        },
                        title: {
                            display: true,
                            text: chartTitle
                        }
                    },
                    scales: {
                        y: {
                            display: chartType === 'pie' ? false : true,
                            beginAtZero: true,
                            min: yAxisMin,
                            max: yAxisMax,
                        },
                    },
                },
            });
        }
    }, [chartType, labels, datasets, yAxisMin, yAxisMax, chartTitle]);
    const handleLabelChange = (index, value) => {
        const newLabels = [...labels];
        newLabels[index] = value;
        setLabels(newLabels);


        const newDatasets = datasets.map(dataset => ({
            ...dataset,
            data: dataset.data.length > newLabels.length
                ? dataset.data.slice(0, newLabels.length)
                : [...dataset.data, ...Array(newLabels.length - dataset.data.length).fill(0)]
        }));
        setDatasets(newDatasets);
    };

    const handleDatasetChange = (datasetIndex, key, value) => {
        if (!labels[datasetIndex]) {
            setLabels(prev => [...prev, 'data'])
        }
        const newDatasets = [...datasets];
        if(!newDatasets[datasetIndex]) {
            newDatasets[datasetIndex] = { label: value, data: new Array(datasetIndex).fill(0), backgroundColor: [] };
        }
        newDatasets[datasetIndex] = {
            ...newDatasets[datasetIndex],
            [key]: key === 'label' ? value : value.split(',').map(Number),
        };
        console.log(newDatasets)

        setDatasets(newDatasets);
    };

    const handleDataChange = (rowIndex, colIndex, value) => {
        const newDatasets = [...datasets];
        if (value > yAxisMax) {
            toast.error('Value greater than Data range end')
            return
        }
        if (value < yAxisMin) {
            toast.error('Value greater than Data range start')
            return
        }
        if (!labels[colIndex]) {
            setLabels(prev => [...prev, 'data'])
        }

        if (!newDatasets[rowIndex]) {
            newDatasets[rowIndex] = { label: `Series ${rowIndex + 1}`, data: new Array(colIndex).fill(0), backgroundColor: [] };
        }
        newDatasets[rowIndex].data[colIndex] = value;
        if (!newDatasets[rowIndex].backgroundColor[colIndex]) {
            newDatasets[rowIndex].backgroundColor[colIndex] = newDatasets[rowIndex].backgroundColor[colIndex] || DEFAULTCOLOR
        }
        setDatasets(newDatasets);


    };
    const handleColorChange = (datasetIndex, color, seriesColor) => {
      
        const newDatasets = [...datasets];
        // if(!newDatasets[datasetIndex]) {
        //     return
        // }
        if (seriesColor) {
            if(newDatasets[datasetIndex]) {
                newDatasets[datasetIndex].backgroundColor = newDatasets[datasetIndex].backgroundColor.map(setcolor => color);
            }
            
        } else {
            newDatasets.map(dataset => {
                dataset.backgroundColor[datasetIndex] = color
            })
        }
        setDatasets(newDatasets);
    };
   
    const handleSubmit = () => {
        const chartDataUrl = canvasRef.current.toDataURL();
        console.log({ type: chartType, labels, datasets, yAxisMin, yAxisMax })
        onInsert(chartDataUrl, { type: chartType, labels, datasets, yAxisMin, yAxisMax, title: chartTitle });
        handleClose();
    };
  
    const handleSaveTemplate = async () => {
        if(chartTitle.length === 0) {
            toast.error('Title is require for saving as template')
            return
        }
        const data = {
            "title": chartTitle,
            "data": { type: chartType, labels, datasets, yAxisMin, yAxisMax, title: chartTitle }
        }
        const res = await dispatch(createChartFeth(data)).unwrap()
        
    }
   
    return (
        <ModalTemplate open={open} modalTheme='add-chart' lightGreyBg={false} handelClose={handleClose} modalSize={'modal__dialog--1000'}>
            <div className="modal__body pt-0">
                <div className="tabs-chart-block">
                    <div className="tabs gap-[32px]">
                        <div className="tabs-header">
                            <ul className="tabs-nav flex-wrap">
                                <li className="tabs-nav--item max-w-max">
                                    <button type='button' onClick={() => { setChartType('bar') }} className={`btn btn-tablink btn-tablink-row btn--md rounded-full bg-transparent px-[10px] ${chartType === 'bar' ? 'active-tab' : ''}`}>

                                        <span className="text--sm font-medium">Bar Chart</span>
                                    </button>
                                </li>
                                <li className="tabs-nav--item max-w-max">
                                    <button type='button' onClick={() => { setChartType('line') }} className={`btn btn-tablink btn-tablink-row btn--md rounded-full bg-transparent px-[10px] ${chartType === 'line' ? 'active-tab' : ''}`}>

                                        <span className="text--sm font-medium">Line Chart</span>
                                    </button>
                                </li>
                                <li className="tabs-nav--item max-w-max">
                                    <button type='button' onClick={() => { setChartType('pie') }} className={`btn btn-tablink btn-tablink-row btn--md rounded-full bg-transparent px-[10px] ${chartType === 'pie' ? 'active-tab' : ''}`}>
                                        <span className="text--sm font-medium">Pie Chart</span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div className="tabs-body">
                            <div className="tabs-item gap-[16px] active-tab" id="bar-chart" style={{ maxHeight: '400px' }}>
                                <canvas ref={canvasRef} width={'100%'} height={'300px'} ></canvas>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-block">
                    {/* <div className="mb-[20px] pb-[20px] border-bottom">
                        <label className="btn btn--shadow btn--xs radius-5">
                            <input type="file" className="hidden" />
                            <span className="ico">
                                <img src={require("../../assets/img/icon-download-arrow.svg").default} />
                            </span>
                            <span className="info text--md">Import Data</span>
                        </label>
                    </div> */}
                    <div className="row gx-12 gy-12">
                        <div className="col-12">
                            <SelectGroupAsynk
                                groupClasses={'items-center gap--lg md:flex-nowrap'}
                                placeholder={'Select a previously saved chart'}
                                wrapperClasses={'select--outline input--md w-full'}
                                // isMulti={true}
                                // value={settingsData?.authorIds?.map((item) => ({ value: item.id, label: item.name }))}
                                // options={savedCharts}
                                onChange={(options) => {
                                    setSelectedChart(options.value)
                                }}
                                fetchFunk={loadOptions}
                              
                            />
                        </div>
                        <div className="col-12">
                            <div className="input-wrapper input--sm">
                                <label className="label">Chart title</label>
                                <input
                                    className="input input--outline"
                                    type="text"
                                    placeholder="Enter chart title"
                                    value={chartTitle}
                                    onChange={(e) => setChartTitle(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-6 mb-[20px]">
                            <div className="input-wrapper input--sm">
                                <label className="label">Data range start</label>
                                <input
                                    className="input input--outline"
                                    type="number"
                                    placeholder="Min"
                                    value={yAxisMin}
                                    onChange={(e) => setYAxisMin(Number(e.target.value))}
                                />
                            </div>
                        </div>
                        <div className="col-6 mb-[20px]">
                            <div className="input-wrapper input--sm">
                                <label className="label">Data range end</label>
                                <input
                                    className="input input--outline"
                                    type="number"
                                    placeholder="Max"
                                    value={yAxisMax}
                                    onChange={(e) => setYAxisMax(Number(e.target.value))}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="add-chart-table">
                        <table className="table-chart">
                            <thead>
                                <tr>
                                    <th style={{ minWidth: "44px" }}>
                                        <a href="#" className="btn">
                                            <svg width="20" height="20" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16.659 10.5269C16.6523 12.2244 16.0082 13.9194 14.7132 15.2144C12.1098 17.8177 7.88815 17.8177 5.28482 15.2144C4.82148 14.751 4.44482 14.2352 4.14648 13.6885" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M3.33594 10.3918C3.3626 8.72183 4.01094 7.06016 5.2851 5.786C7.88844 3.18266 12.1101 3.18266 14.7134 5.786C15.1768 6.24933 15.5534 6.76516 15.8518 7.31183" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M13.293 7.31288H16.2388V4.36621" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M6.7056 13.6875H3.75977V16.6342" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </a>
                                    </th>
                                    {alphabet.map((item, rowIndex) => (
                                        <th style={{ minWidth: "76px" }} key={item}>
                                            <div className="flex items-center gap-2 justify-center">
                                                <span>{item}</span>
                                                {rowIndex > 0 && <input
                                                    type="color"
                                                    className='input-color-picker'
                                                    value={datasets?.[0].backgroundColor[rowIndex - 1] || DEFAULTCOLOR}
                                                    onChange={(e) => handleColorChange(rowIndex - 1, e.target.value)}
                                                />}
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>

                                <tr>
                                    <td>1</td>
                                    {alphabet.map((item, index) => (
                                        <React.Fragment key={item + index}>
                                            {index === 0 ?
                                                <td></td> :
                                                <td>
                                                    <input

                                                        type='text'
                                                        defaultValue={labels[index] || 'data'}
                                                        className='input text-center'
                                                        onChange={(e) => handleLabelChange(index - 1, e.target.value)}
                                                    />
                                                </td>
                                            }
                                        </React.Fragment>
                                    ))}
                                </tr>
                                {cols.map((item, rowIndex) => (
                                    <tr key={rowIndex}>
                                        <td>
                                            <div className="flex items-center justify-center gap-1">
                                                {1 + (rowIndex + 1)}
                                                <input
                                                    type="color"
                                                    className='input-color-picker'
                                                    value={datasets[rowIndex]?.backgroundColor?.[0] || DEFAULTCOLOR}
                                                    onChange={(e) => handleColorChange(rowIndex, e.target.value, true)}
                                                />
                                            </div>
                                        </td>
                                        {alphabet.map((item, colIndex) => (
                                            <React.Fragment key={colIndex + rowIndex}>
                                                {colIndex === 0 ?
                                                    <td>
                                                        <input
                                                            // defaultValue={`${rowIndex < 1 ? 'series ' + (rowIndex + 1) : ''}`}
                                                            type='text'
                                                            defaultValue={datasets[rowIndex]?.label || ('series' + (rowIndex + 1))}
                                                            className='input text-center'
                                                            onChange={(e) => handleDatasetChange(rowIndex, 'label', e.target.value)}
                                                        />
                                                    </td>
                                                    :
                                                    <td>
                                                        <input
                                                            type='number'
                                                            style={{
                                                                paddingLeft: '13px'
                                                            }}
                                                            className='input text-center'
                                                            value={datasets[rowIndex]?.data?.[colIndex - 1] || ''}
                                                            onChange={(e) => handleDataChange(rowIndex, colIndex - 1, e.target.value)}
                                                        />
                                                    </td>
                                                }
                                            </React.Fragment>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="modal__footer">
                <button onClick={handleClose} className="btn btn--shadow btn--md radius-5">Cancel</button>
                <button type='button' onClick={handleSaveTemplate} disabled={isLoading} className="btn btn--primary btn--md ml-auto radius-5">Save as Template</button>
                <button type='button' onClick={handleSubmit} className="btn btn--primary btn--md radius-5">Save</button>
            </div>
        </ModalTemplate>
    )
}

export default AddChartModal
