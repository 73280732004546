import React, { useEffect, useMemo, useState } from 'react'
import Pagination from '../../components/parts/Pagination'
import SidebarFilters from '../../components/parts/SidebarFilters'
import InputSearch from '../../components/UI/formElements/InputSearch'
import SelectGroup from '../../components/UI/formElements/SelectGroup'
import CardCourse from '../../components/parts/Cards/CardCourse'

import { ReactComponent as PlusIcon } from "../../assets/img/ico-plus-2.svg"
import SwitchViewType from '../../components/parts/SwitchViewType'

import { useNavigate, useSearchParams } from 'react-router-dom'
import { filterOptions } from '../../helpers/constants'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { getFilteres } from '../../store/filters/filtersSlice'
import PageLoader from '../../components/parts/PageLoader'
import { getFiltersByName } from '../../store/filters/filtersThukns'
import useDebounce from '../../hooks/useDebounce'
import { getAllCoursesFeth } from '../../store/courses/coursesThunk'
import { setShowLoginModal } from '../../store/auth/authSlice'
import { setClearCourse } from '../../store/courses/coursesSlice'


export const tips = [
    { title: "How to search articles?", id: 1 },
    { title: "How to study with articles?", id: 2 },
    { title: "How to share articles?", id: 3 }
]


const Courses = () => {
    const [isList, setIsList] = useState(false)
    const { authorized, showLoginModal } = useSelector((state) => state.auth)
    const { coursesAll, isLoading, pagination } = useSelector(state => state.courses)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    const systems = useSelector(getFilteres);

    const formatOptionsQuery = (value) => {
		return value?.length
			? value.split(',').filter(Boolean) || []
			: []
	}
	const params = useMemo(() => ({
		sort: searchParams.get('sort') || 'latest',
		course: formatOptionsQuery(searchParams.get('course')) || [],
		search: searchParams.get("search") || ""
	}), [searchParams])
    const filters = [
        {
            title: "Filter by Categories",
            checkboxOptions: systems
        },
    ]
    const handleFilterChange = (options) => {
        setSearchParams({
            ...params,
            course: params.course.includes(options.name)
                ? params.course.filter((cat) => cat !== options.name).join(',')
                : [...params.course, options.name].join(','),
        });
    }
    const debouncedSearchChange = useDebounce((value) => {
        setSearchParams({
            ...params,
            search: value
        });
    }, 500);
    const handleSearchChange = (e) => {
        const { value } = e.target;
        debouncedSearchChange(value);
    };

    useEffect(() => {
        const fetchData = async () => {

            const res = await dispatch(getAllCoursesFeth(searchParams.toString())).unwrap()
            if (res) {
                window.scrollTo(0, 0)
            }
        }
        fetchData()
    }, [params])

    const handleSortChange = (option) => {
        
        setSearchParams({
            ...params,
            sort: option.value,
        });
    }
    useEffect(() => {
        dispatch(getFiltersByName('course'))
    }, [dispatch])


    return (
        <main className="content">
            <div className="container">
                <section className="section-filters">
                    <SidebarFilters filters={filters} defaultCheked={params?.course} tips={tips} onChange={handleFilterChange} />
                    <div className="section-filters__body">
                        <div className="page-heading mb-[30px]">
                            <div className="page-heading__item">
                                <h3 className="mr-[24px]">Courses</h3>
                                <SwitchViewType onChange={(checked) => setIsList(checked)} />
                            </div>
                            <div className="page-heading__item">
                                <InputSearch
                                    className='input--sm w-[250px]'
                                    defaultValue={params.search}
                                    onChange={handleSearchChange}
                                />
                                <SelectGroup
                                    wrapperClasses={'input--sm'}
                                    options={filterOptions}
                                    defaultValue={filterOptions[0]}
                                    onChange={handleSortChange}
                                />
                                <button 
                                    type='button'
                                    onClick={
                                        () => {
                                            if (authorized) {
                                                dispatch(setClearCourse())
                                                navigate('/course-create/general')
                                            } else {
                                                dispatch(setShowLoginModal(true))
                                            }
                                        }}
                                    className="btn btn--primary btn--md gap--xs radius-5"
                                >
                                    <span className="ico ico-22 mr-1">
                                        <PlusIcon />
                                    </span>
                                    <span className="info">Create Course</span>
                                </button>
                            </div>
                        </div>
                        <div className="row gutters-cards flex-auto">
                            {
                                coursesAll && coursesAll.length ?
                                    coursesAll.map(card => (
                                        <div key={card.id} className={!isList ? "col-sm-6 col-lg-4 col-xl-3" : "col-12"} >
                                            <CardCourse data={card} isList={isList} authorized={authorized} />
                                        </div>
                                    ))
                                    : isLoading ?
                                        <PageLoader /> :
                                        <h2>Page is empty</h2>
                            }
                        </div>
                        <Pagination {...pagination} params={params} />
                    </div>
                </section>
            </div>
        </main>
    )
}

export default Courses