import React from 'react'
import { useState } from 'react'
import { useRef } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { capitalize, getFullName } from '../../helpers/utils'
import { useClickOutside } from '../../hooks/useClickOutside'
import useDebounce from '../../hooks/useDebounce'
import { getSearchResultsFetch } from '../../store/search/searchThunks'
import InputSearch from '../UI/formElements/InputSearch'
import Loader from '../UI/Loader'

const GlobalSearch = ({ className = "", handleMenuToggle }) => {
    const dispatch = useDispatch()
    const { pathname } = useLocation()
    const ref = useClickOutside(() => setIsFocused(false))
    const { results, isLoading } = useSelector(state => state.search)
    const [isFocused, setIsFocused] = useState(false)
    const [searchTerm, setSearchTerm] = useState("")
    const handleSearch = (e) => {
        const q = e.target.value
        setSearchTerm(q)
        dispatch(getSearchResultsFetch(q))
    }

    const handleDebouncedSearch = useDebounce(handleSearch, 300)

    const linksMap = {
        "articles": "/articles",
        "cases": "/cases",
        "courses": "/courses",
        "quizzes": "/quiz-page",
        "flashCards": "/flashCards",
        "forums": "/forums",
        "threads": "/threads",
        "materils": "/materials/document",
        "users": "/profile"
    }
    const linksPrefix = {
        "quizzes": "presentation",
        "articles": "",
        "cases": "",
        "courses": "",
        "flashCards": "",
        "forums": "",
        "threads": "",
        "materils": "",
        "users": ""
    }

    const renderResults = (data) => {
        const entries = Object.entries(data);
        const notEmpty = entries.some(([key, value]) => value.length);

        if (!searchTerm) return

        if (!entries?.length || !notEmpty) {
            return (
                <p>Nothing found for the <b>{searchTerm}</b></p>
            )
        }

        entries.sort((a, b) => ((b[1]?.length || 0) - a[1]?.length || 0));

        return (
            <>
                <p>Results for the <b>{searchTerm}</b>:</p>
                {
                    entries.map(([key, values], index) => (
                        values?.length ? (
                            <div key={index} className={index !== 0 ? "border-t pt-3 border-solid border-slate-100" : ""}>
                                <h6 className='mb-2'>{capitalize(key)}</h6>
                                {
                                    values?.length
                                        ? (
                                            <ul>
                                                {
                                                    values.map((value, index) => (
                                                        <li className='ml-2' key={index}>
                                                            <Link className='btn color-pending p-2' onClick={handleMenuToggle} to={`${linksMap[key]}/${value.id}/${linksPrefix[key]}`}>{
                                                                value?.name || value.title || getFullName(value.firstName, value.lastName)
                                                            }</Link>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        )
                                        : <p>No items</p>
                                }
                            </div>
                        ) : null
                    ))

                }
            </>
        )
    }

    

    useEffect(() => {
        setIsFocused(false)
    }, [pathname])

    const notEmpty = Array.isArray(results) ? !!results?.length : results

    return (
        <div ref={ref} className='relative'
            onFocus={() => setIsFocused(true)}>
            <InputSearch

                onChange={handleDebouncedSearch}
                className={`input--md header__search ${className}`}
            />
            {
                notEmpty && (
                    <div
                        className={`dropdown__body overflow-x-hidden max-h-96 right-0 p-3 top-[100%] !absolute ${results && isFocused ? 'is-open' : ''}`}
                    >
                        <div className='flex flex-col gap-3 p-2 min-w-80'>
                            {!isLoading ? renderResults(results) : (
                                <div className='flex flex-col justify-center items-center w-full h-32'>
                                    <Loader className={"m-auto"} />
                                </div>
                            )}
                        </div>
                    </div>
                )
            }
        </div>

    )
}

export default GlobalSearch