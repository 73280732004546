import React, { useState } from 'react'
import LoginModal from '../../components/Modals/LoginModal'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useDispatch } from 'react-redux'
import { Link, Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useValidation } from '../../hooks/useValidation'
import InputGroup from '../../components/UI/formElements/InputGroup'
import { Pagination } from 'swiper/modules'
import { getFormData } from '../../helpers/utils'
import { resetPasswordFetch } from '../../store/auth/authThunks'
import { Button } from 'react-scroll'
import ButtonLoader from '../../components/UI/ButtonLoader'

const RecoveryPassword = () => {
	const dispatch = useDispatch()
	const params = useParams();
	const [searchParams, setSearchParams] = useSearchParams()
	const [isLoading, setIsLoading] = useState(false)
	const [goToHome, setGoToHome] = useState(false)
	const [formData, setFormData] = useState({
		password: "",
		confirmPassword: "",
	})

	const validators = {
		confirmPassword: (value, data) => value === data.password ? true : 'Passwords do not match',
		password: (value) => {
			const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
			return passwordRegex.test(value) ? true : 'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character';
		},
	};

	const messages = {
		password: 'Password is required',
		confirmPassword: 'Confirm password is required',
	};
	const { errors, errorMessages, isErrorShown, setIsErrorShown } = useValidation(formData, {
		validators,
		messages
	});

	const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false)

	const handleLogin = () => {
		setIsLoginPopupOpen(prev => !prev)
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		if (errors) {

			setIsErrorShown(true)
			return
		}
		setIsLoading(true)
		setGoToHome(false)
		const sendData = {
			email: searchParams.get('email'),
			token: params.token,
			password: formData.password,
		}
		const res = await dispatch(resetPasswordFetch(sendData))
		if (res) {
			setIsLoading(false)
			setGoToHome(true)
		}
	}

	const handleChange = (name, value) => {
		setFormData(prev => ({
			...prev, [name]: value
		}))
	}

	if (!searchParams.get('email') || !params.token) {
		return <Navigate to={'/'} />
	}
	if (goToHome) {
		return <Navigate to={'/'} />
	}
	return (
		<>
			<div className="wrapper">
				<main className="content">
					<section className="sing-up-section">
						<div className="sing-up-section__item sing-up-section__body">
							<form className="sing-up-section-form" autoComplete='off' onSubmit={handleSubmit}>
								<Link to={"/"} className="logo max-w-[130px]">
									<img src={require("../../assets/img/logo.svg")} className='max-h-[30px]' alt="" />
								</Link>
								<div className="sing-up-section-form__body my-auto">
									<div className="sing-up-section-form__heading">
										<h2>Recover Password</h2>
									</div>
									<div className="row g-24">
										<div className="col-12">
											<InputGroup
												wrapperClasses=' input--lg'
												label={'New Password'}
												inputClasses='input--outline'
												type='password'
												placeholder={'Enter your password...'}
												autoComplete="new-password"
												value={formData?.password}
												error={isErrorShown && errors?.password}
												errorMessage={errorMessages.password}
												onChange={(e) => handleChange("password", e.target.value)}
											/>
										</div>
										<div className="col-12">
											<InputGroup
												wrapperClasses=' input--lg'
												label={'Confirm New Password'}
												inputClasses='input--outline'
												type='password'
												placeholder={'Enter your password...'}
												autoComplete="new-password"
												value={formData?.confirmPassword}
												error={isErrorShown && errors?.confirmPassword}
												errorMessage={errorMessages.confirmPassword}
												onChange={(e) => handleChange("confirmPassword", e.target.value)}
											/>
										</div>
									</div>
								</div>
								<div className="sing-up-section-form__bottom">
									<button type='submit' disabled={isLoading} className={`btn btn--primary btn--lg radius-5 w-full font-semibold ${isLoading ? 'disabled' : ''}`}>{isLoading ? <ButtonLoader /> : 'Reset Password'}</button>
									<div className="flex color-secondary items-center justify-center w-full text-[13px] gap-3">
										<span>Already have an account?</span>
										<button type='button' className="btn color-danger" onClick={handleLogin}>Login</button>
									</div>
								</div>
							</form>
						</div>
						<div className="sing-up-section__item sing-up-section__image" style={{
							backgroundImage: `url(${require('../../assets/img/sing-up-bg-image.png')})`
						}}>
							<Swiper
								className='sing-up-slider' pagination={{
									el: '.swiper-pagination'
								}} modules={[Pagination]}
							>
								<SwiperSlide className='sing-up-slider--item'>
									<div className="sing-up-slider--item__text">"RCVIR is a game-changer in upgrading my knowledge. Its user-friendly interface and interactive tools make learning enjoyable and accessible. The organized content and insightful feedback have boosted my confidence and deepened my understanding."</div>
									<div className="sing-up-slider--item__name">Leslie Alexander / Medical Student</div>
								</SwiperSlide>
								<SwiperSlide className='sing-up-slider--item'>
									<div className="sing-up-slider--item__text">"RCVIR is a game-changer in upgrading my knowledge. Its user-friendly interface and interactive tools make learning enjoyable and accessible. The organized content and insightful feedback have boosted my confidence and deepened my understanding."</div>
									<div className="sing-up-slider--item__name">Leslie Alexander / Medical Student</div>
								</SwiperSlide>
								<div className="swiper-pagination swiper-pagination--white swiper-pagination--static text-start mt-[24px]"></div>
							</Swiper>
						</div>
					</section>
				</main>
			</div>
			{
				isLoginPopupOpen ?
					<LoginModal
						open={isLoginPopupOpen}
						handelClose={handleLogin}
						linkToRegister={false}
					/>
					:
					null
			}
		</>
	)
}

export default RecoveryPassword
