import { createSlice } from '@reduxjs/toolkit';
import { createForumFeth, createForumSettingsByForumIdFeth, createModeratorFeth, createRuleFeth, createThreadCommentFeth, createThreadFeth, deleteModeratorByModerIdFeth, deleteRuleByRuleIdFeth, editForumSettingsByForumIdFeth, favoriteForumFeth, favoriteThreadFeth, getAllForumsFeth, getAllThreadsFeth, getFavoriteThreadsFetch, getForumFeth, getThreadFeth, getUsersForAssignAsModeratorByForumIdFeth, likeForumFeth, likeThreadFeth, moderatorOrderingByForumIdFeth, subscribeForumFeth, updateForumByIdFeth, updateRuleByRuleIdFeth } from './forumsThunk';

const initialState = {
    forumsAll: [],
    forumOne: null,
    forumId: null,
    threadsAll: [],
    threadOne: null,
    isTreadLoading: false,
    isLoading: false,
    forumUsersByMeders: [],
    errors: null,
    currentRule: null,
    currentRuleId: null,
    isLoadingRule: false,
    pagination: {
        page: null,
        limit: null,
        offset: null,
        maxPages: null,
        total: null
    }
}

const forumsSlice = createSlice({
    name: 'forums',
    initialState,
    reducers: {
        setClearForum: (state, action) => {
            state.forumOne = null
            state.forumId = null
        },
        setForumId: (state, action) => {
            state.forumId = action.payload
        },
        setRuleId: (state, action) => {
            state.currentRuleId = action.payload
        },
        setRule: (state, action) => {
            state.currentRule = action.payload
        },
        setRules: (state, action) => {
            state.forumOne.rules = action.payload
        },
        setThread: (state, action) => {
            state.threadOne = action.payload
        },
    },

    extraReducers(builder) {
        builder
            .addCase(getAllForumsFeth.fulfilled, (state, action) => {
                state.isLoading = false
                state.forumsAll = action.payload?.data
                state.pagination = action.payload?.pagination
            })
            .addCase(getAllForumsFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null;
            })
            .addCase(getAllForumsFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload;
            })
            .addCase(getAllThreadsFeth.fulfilled, (state, action) => {
                state.isLoading = false
                state.threadsAll = action.payload?.data
                state.pagination = action.payload?.pagination
            })
            .addCase(getAllThreadsFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null;
            })
            .addCase(getAllThreadsFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload;
            })
            .addCase(getFavoriteThreadsFetch.fulfilled, (state, action) => {
                state.isLoading = false
                state.threadsAll = action.payload?.data
                state.pagination = action.payload?.pagination
            })
            .addCase(getFavoriteThreadsFetch.pending, (state, action) => {
                state.isLoading = true
                state.errors = null;
            })
            .addCase(getFavoriteThreadsFetch.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload;
            })
            .addCase(getForumFeth.fulfilled, (state, action) => {
                state.isLoading = false
                if (action.payload) {
                    state.forumOne = action.payload
                    state.forumId = action.payload.id
                }
            })
            .addCase(getForumFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null;
            })
            .addCase(getForumFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload;
            })
            .addCase(createForumFeth.fulfilled, (state, action) => {
                state.isLoading = false
                if (action.payload) {
                    state.forumOne = action.payload
                    state.forumId = action.payload.id
                }
            })
            .addCase(createForumFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null;
            })
            .addCase(createForumFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload;
            })
            .addCase(updateForumByIdFeth.fulfilled, (state, action) => {
                state.isLoading = false
                if (action.payload) {
                    state.forumOne = {...state.forumOne, ...action.payload}
                   
                }
            })
            .addCase(updateForumByIdFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null;
            })
            .addCase(updateForumByIdFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload;
            })
            .addCase(getThreadFeth.fulfilled, (state, action) => {
                state.isLoading = false
                if (action.payload) {
                    state.threadOne = action.payload?.thread
                }
            })
            .addCase(getThreadFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null;
            })
            .addCase(getThreadFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload;
            })
            .addCase(createRuleFeth.fulfilled, (state, action) => {
                state.isLoadingRule = false
                state.currentRule = action.payload
                state.currentRuleId = action.payload.id
                state.forumOne.rules.push(action.payload)
            })
            .addCase(createRuleFeth.pending, (state, action) => {
                state.isLoadingRule = true
                state.errors = null;
            })
            .addCase(createRuleFeth.rejected, (state, action) => {
                state.isLoadingRule = false
                state.errors = action.payload;
            })
            .addCase(createThreadFeth.fulfilled, (state, action) => {
                state.isTreadLoading = false
                state.threadsAll.push(action.payload)
                state.threadOne = action.payload
            })
            .addCase(createThreadFeth.pending, (state, action) => {
                state.isTreadLoading = true
                state.errors = null;
            })
            .addCase(createThreadFeth.rejected, (state, action) => {
                state.isTreadLoading = false
                state.errors = action.payload;
            })
            .addCase(updateRuleByRuleIdFeth.fulfilled, (state, action) => {
                const ruleIndex = state.forumOne.rules.findIndex(rule => rule.id === action.payload.id)
                if (ruleIndex >= 0) {
                    state.forumOne.rules[ruleIndex] = action.payload
                }
                state.isLoadingRule = false
                state.currentRule = action.payload
            })
            .addCase(updateRuleByRuleIdFeth.pending, (state, action) => {
                state.isLoadingRule = true
                state.errors = null;
            })
            .addCase(updateRuleByRuleIdFeth.rejected, (state, action) => {
                state.isLoadingRule = false
                state.errors = action.payload;
            })
            .addCase(deleteRuleByRuleIdFeth.fulfilled, (state, action) => {
                state.isLoadingRule = false
                if (action.payload) {
                    const filteredRules = state.forumOne.rules.filter(item => item.id !== action.payload.id);
                    state.forumOne.rules = filteredRules.map((item, index) => ({
                        ...item,
                        order: index
                    }))
                }
            })
            .addCase(getUsersForAssignAsModeratorByForumIdFeth.fulfilled, (state, action) => {
                state.forumUsersByMeders = action.payload?.data
                state.isModeratorsLoading = false
                state.paginationModerators = action.payload?.pagination
            })
            .addCase(getUsersForAssignAsModeratorByForumIdFeth.pending, (state, action) => {
                state.isModeratorsLoading = true
            })
            .addCase(getUsersForAssignAsModeratorByForumIdFeth.rejected, (state, action) => {
                state.isModeratorsLoading = false
            })
            .addCase(createForumSettingsByForumIdFeth.fulfilled, (state, action) => {
                state.isLoading = false
                state.forumOne.settings = action.payload
            })
            .addCase(createForumSettingsByForumIdFeth.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(createForumSettingsByForumIdFeth.rejected, (state, action) => {
                state.isLoading = false
            })
            .addCase(editForumSettingsByForumIdFeth.fulfilled, (state, action) => {
                state.isLoading = false
                state.forumOne.settings = action.payload
            })
            .addCase(editForumSettingsByForumIdFeth.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(editForumSettingsByForumIdFeth.rejected, (state, action) => {
                state.isLoading = false
            })
            .addCase(createModeratorFeth.fulfilled, (state, action) => {
                if(state.forumOne.moderators) {
                    state.forumOne.moderators = [
                        ...state.forumOne.moderators,
                        ...action.payload
                    ]
                } else {
                    state.forumOne.moderators = [action.payload]
                }
                
            })
            .addCase(deleteModeratorByModerIdFeth.fulfilled, (state, action) => {
                if (action.payload) {
                    state.forumOne.moderators = state.forumOne.moderators.filter(moder => moder.id !== action.payload.id)
                }
            })
            .addCase(likeForumFeth.fulfilled, (state, action) => {
                let currentArticle = state.forumsAll.find(item => item.id === action.payload.id);
                if (currentArticle) {
                    currentArticle.isLiked = action.payload.isLiked
                    currentArticle.likes = action.payload.likes
                }
                if (state.article) {
                    state.forumOne.isLiked = action.payload.isLiked
                    state.forumOne.likes = action.payload.likes
                }
            })
            .addCase(favoriteForumFeth.fulfilled, (state, action) => {
                let currentArticle = state.forumsAll.find(item => item.id === action.payload.id);
                if (currentArticle) {
                    currentArticle.isFavorite = action.payload.isFavorite
                }
                if (state.article) {
                    state.forumOne.isFavorite = action.payload.isFavorite
                }

            })
            .addCase(subscribeForumFeth.fulfilled, (state, action) => {
                state.forumOne.isSubscribed = action.payload.isSubscribed
                state.forumOne.subscribers = action.payload.subscribers
            })
            .addCase(likeThreadFeth.fulfilled, (state, action) => {
                state.threadOne.isLiked = action.payload.isLiked
                state.threadOne.likes = action.payload.likes
            })
            .addCase(favoriteThreadFeth.fulfilled, (state, action) => {
                state.threadOne.isFavorite = action.payload.isFavorite
            })
            .addCase(moderatorOrderingByForumIdFeth.fulfilled, (state, action) => {
                state.isLoading = false
            })
            .addCase(createThreadCommentFeth.fulfilled, (state, action) => {
                state.threadOne.comments += 1
            })
            .addCase(moderatorOrderingByForumIdFeth.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(moderatorOrderingByForumIdFeth.rejected, (state, action) => {
                state.isLoading = false
            })
    }
})

export const {
    setClearForum,
    setForumId,
    setRuleId,
    setRule,
    setRules,
    setThread
} = forumsSlice.actions;


export default forumsSlice.reducer