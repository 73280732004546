import React, { useState } from 'react';
import { ReactComponent as PlaceholderIcon } from "../../assets/img/user-placeholder.svg"
import {
    DndContext,
    DragEndEvent,
} from '@dnd-kit/core';
import {
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
    arrayMove,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ReactComponent as DotsIcon } from '../../assets/img/ico-dots-2.svg';
import { getImageSrc } from '../../helpers/utils';
const DraggableItem = ({ id, data, editHandler, removeHandler }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id });

    const style = {
        transform: CSS.Translate.toString(transform),
        transition,
        zIndex: isDragging ? 1000 : 'auto',
        boxShadow: isDragging ? '0 4px 10px rgba(0, 0, 0, 0.3)' : 'none',
        touchAction: 'none'
    };
    return (
        <div
            ref={setNodeRef}
            className="question-card card"
            style={style}
        >
            <span {...listeners} style={{ cursor: 'grab' }} className="question-card__handle ui-sortable-handle">
                <span className="ico">
                    <DotsIcon />
                </span>
            </span>
            <div className="card__body">
                <div className="row-group gap-[14px]">
                    <div className="image-wrapper ico ico--md rounded-full">
                        {data?.user?.photo ?
                        <img src={getImageSrc(data?.user?.photo)} />
                        : <PlaceholderIcon />}
                    </div>
                    <div className="col-group gap--xs">
                        {data?.user?.firstName && <p className="text--sm w-full">{`${data?.user?.firstName} ${data?.user?.lastName}`}</p>}
                        <p className="text--sm color-secondary">{data?.email}</p>
                    </div>
                </div>
            </div>
            <div className="card__actions row-group gap-[14px]">
                <span className={`pill-custom pill-2 pill-status ${data?.status === 'active' ? '--success' : data?.status === 'pending' ? '--warn-2' : '--danger'}`}>
                    <span className="pill-text">{data?.status}</span>
                </span>
                <button type='button'
                    onClick={(e) => {
                        e.preventDefault()
                        removeHandler(data.id)
                    }}
                    className="btn btn--shadow btn--square btn--xs rounded-full">
                    <span className="ico">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.66669 6.66675L13.3334 13.3334"
                                stroke="#191919" strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"></path>
                            <path d="M13.3334 6.66675L6.66669 13.3334"
                                stroke="#191919" strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"></path>
                        </svg>
                    </span>
                </button>
            </div>
            <div {...attributes} style={{ display: 'none' }} />
        </div>
    );
};


const SortModerators = ({ items, setItems, editHandler, removeHandler }) => {
    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (over && active.id !== over.id) {

            const oldIndex = items.findIndex(item => item.order === parseInt(active.id));
            const newIndex = items.findIndex(item => item.order === parseInt(over.id));
            const updatedAnswers = arrayMove(items, oldIndex, newIndex).map((item, index) => ({
                ...item,
                order: index,
            }));
            setItems([...updatedAnswers]);
        }
    };

    return (
        <div className="questions-flow mb-[16px]">
            <DndContext onDragEnd={handleDragEnd}>
                <SortableContext items={items.map(item => item.order.toString())}>
                    {items.map((item) => (
                        <DraggableItem key={item.id} id={item.order.toString()} data={item} editHandler={editHandler} removeHandler={removeHandler} />
                    ))}
                </SortableContext>
            </DndContext>
        </div>
    );
}

export default SortModerators
