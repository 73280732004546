import Nouislider from 'nouislider-react';
import 'nouislider/distribute/nouislider.css';
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Circle, Image as KonvaImage, Layer, Line, Stage, Group } from 'react-konva';
import { useImage } from "react-konva-utils";

import { ReactComponent as DrawIcon } from "../../assets/img/ico-draw.svg";
import { ReactComponent as EraseIcon } from "../../assets/img/ico-erase.svg";
import { ReactComponent as HintsIcon } from "../../assets/img/ico-hint-cloud.svg";


const fileToDataImage = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = () => {
            resolve(reader.result);
        };

        reader.onerror = (error) => {
            reject(error);
        };

        reader.readAsDataURL(file);
    });
};
const HINT_OPTION = {
    radius: 8,
    strokeWidth: 13,
    strokeDefaultColor: "#19191932",
    strokeActiveHintColor: '#FFE47F',
    fillDragColor: '#FFE47F',
    filDefaultColor: '#ffffff',
    filActiveColor: '#191919'
}
const DrawingComponent = React.forwardRef(({ image = null, hintsSelected = {}, selectedIndex = 0, setItems, showOnly = false }, stageRef) => {
    // const [lines, setLines] = useState([]);

    const isDrawing = useRef(false);
    const containerRef = useRef();

    const [tool, setTool] = useState("");
    const [color, setColor] = useState('#000000');
    const [lineWidth, setLineWidth] = useState(5);
    const [eraserSize, setEraserSize] = useState(20);
    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
    const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
    const [imagePosition, setImagePosition] = useState({ x: 0, y: 0 });
    const [imageSrc, setImageSrc] = useState(null);
    const [loadedImage] = useImage(imageSrc);
    const [isDragging, setIsDragging] = useState(false);
    const [isActiveHitn, setIsActiveHiht] = useState(false)
    const [scales, setScales] = useState({ scaleX: 1, scaleY: 1 })
    const [dimensions, setDimensions] = useState({
        width: 0,
        height: 0
    });
    const [dropStyles, setDropStyles] = useState(
        {
            position: 'absolute',
            left: 0,
            top: 0,
            maxHeight: '300px',
            width: dimensions.width,
            overflow: 'hidden',
            paddingBottom: '50px'
        }
    )
    const dropRef = useRef();
    const updateCanvasDimensions = () => {
        if (containerRef.current) {
            setDimensions({
                width: containerRef.current.offsetWidth,
                height: containerRef.current.offsetHeight,
            });
        }
    };
    useEffect(() => {
        updateCanvasDimensions();
        window.addEventListener('resize', updateCanvasDimensions);
        return () => window.removeEventListener('resize', updateCanvasDimensions);
    }, []);
    useEffect(() => {
        if (containerRef.current) {
            setDimensions({
                width: containerRef.current.offsetWidth,
                height: containerRef.current.offsetHeight,
            })

        }
        if (loadedImage) {
            setImageSize({
                width: loadedImage.width * calculateScale().x,
                height: loadedImage.height * calculateScale().y,
            })
            setImagePosition({
                x: calculateOffset().x,
                y: calculateOffset().y
            })
        }
    }, [containerRef, loadedImage, image])
    const [activeHint, setActiveHiht] = useState({
        content: ''
    })
    useEffect(() => {
        setIsActiveHiht(false)
        setTool('')
    }, [imageSrc])
    useEffect(() => {
        if (isActiveHitn) {
            setActiveHiht(...hintsSelected?.hintTexts?.filter(item => item.id === isActiveHitn))
        }
    }, [isActiveHitn, hintsSelected])
    useEffect(() => {
        if (dropRef?.current) {
            let position = (activeHint?.position?.x * calculateScale().x + calculateOffset().x) - 10

            if (position + dropRef?.current?.offsetWidth > dimensions.width) {
                position = dimensions.width - dropRef.current.offsetWidth
            }
            setDropStyles(prev => ({
                ...prev,
                width: dimensions.width,
                top: (activeHint?.position?.y * calculateScale().y + calculateOffset().y) + 10,
                left: position
            }))
        }
    }, [dimensions, activeHint, dropRef])

    useEffect(() => {

        const loadImage = async () => {
            if (image instanceof File) {
                try {
                    const dataUrl = await fileToDataImage(image);
                    setImageSrc(dataUrl);
                } catch (error) {
                    console.error("Error converting file to Data URL:", error);
                }
            } else {
                setImageSrc(image?.preview);

            }
        };
        loadImage();
    }, [image]);

    // useEffect(() => {
    //     if (loadedImage) {
    //         const containerWidth = containerRef?.current?.clientWidth;
    //         const containerHeight = containerRef?.current?.clientHeight;
    //         const imageWidth = loadedImage.width;
    //         const imageHeight = loadedImage.height;
    //         const scale = Math.min(containerWidth / imageWidth, containerHeight / imageHeight);
    //         updateDimensions() 
    //         setImageSize({
    //             width: imageWidth * scale,
    //             height: imageHeight * scale,
    //         });
    //     }
    // }, [loadedImage]);
    const calculateScale = () => {
        if (!loadedImage) return { x: 1, y: 1 };
        const scaleX = dimensions.width / loadedImage.width;
        const scaleY = dimensions.height / loadedImage.height;
        const scale = Math.min(scaleX, scaleY);
        return { x: scale, y: scale };
    };

    const calculateOffset = () => {
        if (!loadedImage) return { x: 0, y: 0 };

        const scaledWidth = loadedImage.width * calculateScale().x;
        const scaledHeight = loadedImage.height * calculateScale().y;
        const offsetX = (dimensions.width - scaledWidth) / 2;
        const offsetY = (dimensions.height - scaledHeight) / 2;
        return { x: offsetX, y: offsetY };
    };
    const scaledLines = hintsSelected?.hintMarkers?.map(line => ({
        ...line,
        points: line.points.map((p, i) =>
            i % 2 === 0
                ? p * calculateScale().x + calculateOffset().x
                : p * calculateScale().y + calculateOffset().y
        ),
    }));
    const scaledHints = hintsSelected?.hintTexts?.map(hint => ({
        ...hint,
        position: { x: hint.position.x * calculateScale().x + calculateOffset().x, y: hint.position.y * calculateScale().y + calculateOffset().y }
    }))
    const addHint = (pos) => {
        setItems(prev => prev.map((item, index) => {
            if (index === selectedIndex) {
                return {
                    ...prev[selectedIndex],
                    hintTexts: [...item.hintTexts, { id: 'my' + prev[selectedIndex].hintTexts.length + 1, content: "", position: { x: pos.x, y: pos.y } }]
                }
            } else {
                return item
            }
        }))
    };
    const removeHintHandler = () => {
        setItems(prev => prev.map((item, index) => {
            if (index === selectedIndex) {
                return {
                    ...prev[selectedIndex],
                    hintTexts: prev[selectedIndex].hintTexts.filter(item => item.id !== activeHint.id)
                }
            } else {
                return item
            }
        }))
        setIsActiveHiht(false)
    }
    const handleMouseDown = (e) => {
        if (!tool) return;

        const pos = e.target.getStage().getPointerPosition();
        const adjustedPos = {
            x: (pos.x - calculateOffset().x) / calculateScale().x,
            y: (pos.y - calculateOffset().y) / calculateScale().y,
        };
        if (adjustedPos.x < 0 || adjustedPos.y < 0 || adjustedPos.y > loadedImage.height || adjustedPos.x > loadedImage.width) return
        if (tool === "hint" && e.target?.parent?.parent?.index !== 2 && e.target?.parent) {
            addHint(adjustedPos)
            return;
        }
        if (tool !== "hint") {
            isDrawing.current = true;
            setItems(prev => prev.map((item, index) => {
                if (index === selectedIndex) {
                    return {
                        ...item,
                        hintMarkers: [...item.hintMarkers, { tool, points: [adjustedPos.x, adjustedPos.y], strokeWidth: tool === 'erase' ? eraserSize : lineWidth, color }]
                    }
                } else {
                    return item
                }
            }))
        }
    };
    const handleMouseMove = (e) => {
        if (showOnly) return
        const stage = e.target.getStage();
        const point = stage.getPointerPosition();
        const adjustedPos = {
            x: (point.x - calculateOffset().x) / calculateScale().x,
            y: (point.y - calculateOffset().y) / calculateScale().y,
        };
        setCursorPosition(point);
        if (!isDrawing.current) return;
        if (adjustedPos.x < 0 || adjustedPos.y < 0 || adjustedPos.y > loadedImage.height || adjustedPos.x > loadedImage.width) return
        let lastLine = hintsSelected.hintMarkers[hintsSelected.hintMarkers.length - 1];
        lastLine.points = lastLine.points.concat([adjustedPos.x, adjustedPos.y]);
        hintsSelected.hintMarkers.splice(hintsSelected.hintMarkers.length - 1, 1, lastLine);
        setItems(prev => prev.map((item, index) => {
            if (index === selectedIndex) {
                return {
                    ...item,
                    hintMarkers: hintsSelected.hintMarkers.concat()
                }
            } else {
                return item
            }
        }))
    };

    const handleMouseUp = () => {
        isDrawing.current = false;
    };
    const dragEndHints = (e, id) => {

        setIsDragging(false)
        const adjustedPos = {
            x: (e.target.x() - calculateOffset().x) / calculateScale().x,
            y: (e.target.y() - calculateOffset().y) / calculateScale().y,
        };
        if (adjustedPos.x < 0) {
            adjustedPos.x = 10
        }
        if (adjustedPos.y < 0) {
            adjustedPos.y = 10
        }
        if (adjustedPos.y > loadedImage.height) {
            adjustedPos.y = loadedImage.height - 10
        }
        console.log(adjustedPos.y)
        if (adjustedPos.x > loadedImage.width) {
            adjustedPos.x = loadedImage.width - 10
        }
        setItems(prev => prev.map((item, index) => {
            if (index === selectedIndex) {
                return {
                    ...item,
                    hintTexts: item.hintTexts.map(hint => {
                        if (hint.id === id) {

                            const newHint = {
                                ...hint,
                                position: {
                                    x: adjustedPos.x,
                                    y: adjustedPos.y,
                                }
                            }
                            return newHint
                        } else {
                            return hint
                        }
                    })
                }
            } else {
                return item
            }
        }))

    }
    const commentHandler = ({ target }) => {
        setItems(prev => prev.map((item, index) => {
            if (index === selectedIndex) {
                return {
                    ...item,
                    hintTexts: item.hintTexts.map(hint => {
                        if (hint.id === isActiveHitn) {

                            const newHint = {
                                ...hint,
                                content: target.value
                            }
                            return newHint
                        } else {
                            return hint
                        }
                    })
                }
            } else {
                return item
            }
        }))
        setActiveHiht(prev => ({
            ...prev,
            content: target.value
        }))
    }
    const handleColorChange = (e) => {
        setColor(e.target.value);
    };

    const handleLineWidthChange = (value) => {
        setLineWidth(parseInt(value[0]));
    };

    const handleEraserSizeChange = (value) => {
        setEraserSize(parseInt(value[0]));
    };
    const handlerClickHint = (id) => {
        setIsActiveHiht(id === isActiveHitn ? false : id)
    }
    
    if (!hintsSelected && !loadedImage) return null
    return (
        <div className="canvas-workspace" style={{ height: '100%', overflow: "visible", padding: '20px 0' }}>
            <div className="canvas-container flex-auto">
                <div className="draw-canvas-content" ref={containerRef} style={{
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    margin: "auto"
                }}>
                    <Stage
                        width={dimensions.width}
                        height={dimensions.height}
                        onMouseDown={handleMouseDown}
                        onMousemove={handleMouseMove}
                        onMouseup={handleMouseUp}
                        onTouchStart={handleMouseDown}
                        onTouchMove={handleMouseMove}
                        onTouchEnd={handleMouseUp}
                        ref={stageRef}
                    // scaleX={dimensions.width / containerRef?.current?.offsetWidth}
                    // scaleY={dimensions.height / containerRef?.current?.offsetHeight}
                    >

                        <Layer>
                            {loadedImage && (
                                <KonvaImage
                                    image={loadedImage}
                                    width={imageSize.width}
                                    height={imageSize.height}
                                    x={imagePosition.x}
                                    y={imagePosition.y}
                                />
                            )}
                        </Layer>
                        <Layer>
                            {scaledLines?.map((line, i) => (
                                <Line
                                    key={i}
                                    points={line.points}
                                    stroke={line.tool === 'erase' ? 'white' : line.color}
                                    strokeWidth={line.strokeWidth}
                                    tension={0.5}
                                    lineCap="round"
                                    globalCompositeOperation={
                                        line.tool === 'erase' ? 'destination-out' : 'source-over'
                                    }
                                />
                            ))}
                            <Circle
                                x={cursorPosition.x}
                                y={cursorPosition.y}
                                radius={tool === 'erase' ? eraserSize / 2 : lineWidth / 2}
                                stroke={tool === 'erase' ? 'gray' : color}
                                strokeWidth={1}
                                dash={[4, 4]}
                            />
                        </Layer>
                        {(tool === 'hint' || showOnly) &&
                            <Layer>
                                {scaledHints?.map((hint, index) => (
                                    <Group
                                        key={index}
                                        draggable={!showOnly}
                                        x={hint.position.x}
                                        y={hint.position.y}
                                        onDragStart={() => {
                                            if (showOnly) return
                                            setIsDragging(hint.id)
                                        }}
                                        onDragEnd={(e) => {
                                            if (showOnly) return
                                            dragEndHints(e, hint.id)
                                        }
                                        }
                                        onClick={() => {
                                            handlerClickHint(hint.id)
                                        }}
                                        onTap={() => {
                                            handlerClickHint(hint.id)
                                            // setIsActiveHiht(hint.id === isActiveHitn ? false : hint.id)
                                        }}
                                    >
                                        <Circle
                                            radius={HINT_OPTION.radius}
                                            stroke={
                                                isDragging === hint.id ?
                                                    HINT_OPTION.strokeDefaultColor :
                                                    isActiveHitn === hint.id ?
                                                        HINT_OPTION.strokeActiveHintColor :
                                                        HINT_OPTION.strokeDefaultColor
                                            }
                                            strokeWidth={HINT_OPTION.strokeWidth}
                                            fill={`
                                                ${isDragging === hint.id ?
                                                    HINT_OPTION.fillDragColor :
                                                    isActiveHitn === hint.id ?
                                                        HINT_OPTION.filActiveColor :
                                                        HINT_OPTION.filDefaultColor}`
                                            }
                                            fillAfterStrokeEnabled
                                        />

                                    </Group>
                                ))}
                            </Layer>
                        }
                    </Stage>
                    {isActiveHitn && !isDragging &&
                        <div className="dropdown__body dropdown-hint__body dropdown-hint__body--creation is-open" style={dropStyles} ref={dropRef}>
                            <button
                                type='button'
                                className="btn close-hintbtn"
                                onClick={() => handlerClickHint(false)}
                            >
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.1875 4.8125L4.8125 17.1875" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M17.1875 17.1875L4.8125 4.8125" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </button>
                            {!showOnly ? <>
                                <textarea className="textarea" value={activeHint?.content} onChange={commentHandler} placeholder="Leave your comment here..." style={{ resize: 'none', height: '100%', }}></textarea>
                                <button
                                    type='button'
                                    className="btn"
                                    onClick={removeHintHandler}
                                >
                                    <span className="ico ico-22">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M14.7928 19.2498H7.20276C6.24301 19.2498 5.44551 18.5092 5.37401 17.5513L4.55176 6.4165H17.4163L16.6215 17.5467C16.5528 18.5064 15.7543 19.2498 14.7928 19.2498V19.2498Z" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M11.0003 10.0835V15.5835" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M3.66699 6.41683H18.3337" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M15.5837 6.41667L14.6551 3.93983C14.3865 3.22392 13.7027 2.75 12.9382 2.75H9.06249C8.29799 2.75 7.61416 3.22392 7.34558 3.93983L6.41699 6.41667" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M14.1437 10.0835L13.7495 15.5835" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M7.85598 10.0835L8.25014 15.5835" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </span>
                                </button>
                            </>
                                :
                                <p>{activeHint?.content}</p>
                            }
                        </div>}
                </div>
            </div>
            {!showOnly && <ul className="vertical-controls">
                <li className="control-item">
                    <div className={`control-tool ${tool === "hint" ? "tool_active" : ""}`}>
                        <button
                            onClick={() => setTool(tool !== "hint" ? "hint" : "")}
                            className="btn btn--shadow-2 btn--square btn--lg rounded-full control-tool-btn"
                            disabled={!imageSrc ? true : false}
                        >
                            <span className="ico">
                                <HintsIcon />
                            </span>
                        </button>
                    </div>
                </li>
                <li className="control-item">
                    <div className={`control-tool ${tool === "draw" ? "tool_active" : ""}`}>
                        <button
                            className="btn btn--shadow-2 btn--square btn--lg rounded-full control-tool-btn"
                            onClick={() => setTool(tool !== "draw" ? "draw" : "")}
                            disabled={!imageSrc ? true : false}
                        >
                            <span className="ico">
                                <DrawIcon />
                            </span>
                        </button>
                        <div className="control-tool-menu" style={{ display: tool === "draw" ? "flex" : "none" }}>
                            <label className="btn btn--shadow-2 btn--square btn--md rounded-full">
                                <input type="color" className="custom-colorpicker" value={color} onChange={handleColorChange} />
                            </label>
                            <div className="btn btn--shadow-2 rounded-full btn--md w-[40px] py-[14px]">
                                <Nouislider
                                    range={{ min: 1, max: 50 }}
                                    start={lineWidth}
                                    onChange={handleLineWidthChange}
                                    className={"slider-1 custom-slider none-tooltips"}
                                    orientation={"vertical"}
                                    connect={[true, false]}
                                />
                            </div>
                        </div>
                    </div>
                </li>
                <li className="control-item">
                    <div className={`control-tool ${tool === "erase" ? "tool_active" : ""}`}>
                        <button
                            onClick={() => setTool(tool !== "erase" ? "erase" : "")}
                            className="btn btn--shadow-2 btn--square btn--lg rounded-full control-tool-btn"
                            disabled={!imageSrc ? true : false}
                        >
                            <span className="ico">
                                <EraseIcon />
                            </span>
                        </button>
                        <div className="control-tool-menu" style={{ display: tool === "erase" ? "flex" : "none" }}>
                            <div className="btn btn--shadow-2 rounded-full btn--md w-[40px] py-[14px]">
                                <Nouislider
                                    range={{ min: 1, max: 50 }}
                                    start={eraserSize}
                                    onChange={handleEraserSizeChange}
                                    className="slider-1 custom-slider none-tooltips"
                                    orientation="vertical"
                                    connect={[true, false]}
                                />
                            </div>
                        </div>
                    </div>
                </li>
            </ul>}
        </div>
    );
});

export default DrawingComponent;