import React, { useState } from 'react';
import SelectGroup from '../UI/formElements/SelectGroup';
import { useSearchParams } from 'react-router-dom';
import { retry } from 'ckeditor5';
import ReactPaginate from 'react-paginate';

const Pagination = ({ maxPages = 1, limit = 12, page = 1, onPageChange = () => { }, onItemsPerPageChange = () => { }, setParams, params }) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [currentPage, setCurrentPage] = useState(page);
    const [itemsPerPage, setItemsPerPage] = useState(limit);

    const handleChange = (name, value) => {
        if (setParams) {
            setParams(prev => {
                return {
                    ...prev,
                    [name]: value
                }
            })
        }
        setSearchParams({
            ...params,
            [name]: value
        });
    }
    const itemsPerPageOptions = [
        { value: "12", label: "12 items per page" },
        { value: "24", label: "24 items per page" },
        { value: "52", label: "52 items per page" },
    ]

    const handlePageChange = (page) => {
        setCurrentPage(page);
        handleChange("page", page)
        // setParams('page', page)
        onPageChange(page);
    };

    const handlePrevious = (e) => {
        e.preventDefault();
        if (currentPage > 1) {
            handlePageChange(currentPage - 1);
        }
    };

    const handleNext = (e) => {
        e.preventDefault()
        if (currentPage < maxPages) {
            handlePageChange(currentPage + 1);
        }
    };

    const handleItemsPerPageChange = (selectedOption) => {
        setItemsPerPage(parseInt(selectedOption.value, 10));
        onItemsPerPageChange(parseInt(selectedOption.value, 10));
        handleChange("limit", parseInt(selectedOption.value, 10))
    };

    const generatePageNumbers = () => {
        const pages = [];
        for (let i = 1; i <= maxPages; i++) {
            pages.push(i);
        }
        return pages;
    };

    if (maxPages <= 1) return null

    return (
        <div className="pagination-block mt-[30px]">
            <a
                href="#"
                className={`btn btn--primary btn--lg radius-5 ${page === 1 ? 'disabled' : ''}`}
                onClick={(e) => handlePrevious(e)}
            >
                <span className="ico">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 12H19" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M10 7L5 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M10 17L5 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </span>
                <span className="ml-[10px] hidden md:inline text-[14px]">Previous</span>
            </a>
            <SelectGroup
                wrapperClasses={'input--lg min-w-[180px]'}
                options={itemsPerPageOptions}
                defaultValue={itemsPerPageOptions[0]}
                onChange={handleItemsPerPageChange}
            />
            {/* <ul className="pagination-block--nav">
                {generatePageNumbers().map((pageNumber) => (
                    <li key={pageNumber}>
                        <a
                            href="#"
                            className={pageNumber === page ? 'active' : ''}
                            onClick={(e) => {
                                e.preventDefault();
                                handlePageChange(pageNumber);
                            }}
                        >
                            {pageNumber}
                        </a>
                    </li>
                ))}
            </ul> */}
            <ReactPaginate
                breakLabel="..."
                nextLabel={null}
                previousLabel={null}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={Math.ceil(maxPages)}
                total={Math.ceil(maxPages)}
                onPageChange={(selectedItem) => handlePageChange(selectedItem.selected + 1)}
                forcePage={page - 1}
                className="pagination-block--nav"
                // pageClassName="pagination__item"
                // pageLinkClassName="pagination__btn"
                activeLinkClassName='active'
                previousClassName="pagination__previous"
                nextClassName="pagination__next"
            />
            <a
                href="#"
                className={`btn btn--primary btn--lg radius-5 ${page === maxPages ? 'disabled' : ''}`}
                onClick={(e) => handleNext(e)}
            >
                <span className="mr-[10px] hidden md:inline text-[14px]">Next Page</span>
                <span className="ico">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19 12H5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M14 17L19 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M14 7L19 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </span>
            </a>
        </div>
    );
};

export default Pagination;
