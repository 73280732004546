import React from 'react'
import ModalTemplate from './ModalTemplate'
import { Link, useParams } from 'react-router-dom'

const SuccesPaymant = ({open, handelClose, blockCloseBg, type}) => {
    const {id} = useParams()
  return (
    <ModalTemplate open={open} handelClose={handelClose} showHeader={false} blockCloseBg = {blockCloseBg}modalSize='modal__dialog--410'>
        <div className="modal__body items-center pt-0">
            <div className="successfull-paymant">
                <div className="successfull-paymant__content flex-auto">
                    <img src={require("../../assets/img/successfull-image.png")} alt=""/>
                    <h2 className="successfull-paymant__title mb-[16px]">Payment Successful</h2>
                    <p className="successfull-paymant__subtitle mb-[24px]">Thank you for choosing RCVIR</p>
                    {type === 'pricing' && <Link to={'/'} className="btn btn--primary btn--lg radius-5">Back to Home</Link>}
                    {type === 'course' && <Link to={`/courses/${id}`} className="btn btn--primary btn--lg radius-5">Back to Course</Link>}
                </div>
            </div>
        </div>
    </ModalTemplate>
  )
}

export default SuccesPaymant
