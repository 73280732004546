import { CreateFormHeader } from "../../components/parts/CreateFormHeader";
import { useEffect, useState } from "react";
import { AddModeratorModal } from "../../components/Modals/AddModeratorModal";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import SortModerators from "../../components/parts/SortModerators";
import { ReactComponent as ArrowRightIcon } from '../../assets/img/ico-arrow-right.svg'
import { deleteModeratorByModerIdFeth, moderatorOrderingByForumIdFeth } from "../../store/forums/forumsThunk";
import Loader from "../../components/UI/Loader";

export const ForumModerators = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { forumOne, forumId, isLoading } = useSelector(state => state.forums)
    const [moderators, setModerators] = useState([])
    useEffect(() => {
        setModerators(forumOne?.moderators)
    }, [forumOne])
    const [openAddModer, setOpenAddModer] = useState(false)

    const openModal = () => {
        setOpenAddModer(true)
    }

    const closeModal = () => {
        setOpenAddModer(false)
    }

    const removeHandler = async (id) => {
        console.log(id)
        const res = await dispatch(deleteModeratorByModerIdFeth(id)).unwrap()
    }

    const submitHandler = async () => {
        const orderingData = moderators.map((item, index) => ({
            id: item.id,
            order: index
        }))
        const res = await dispatch(moderatorOrderingByForumIdFeth({
            id: forumId, body: {
                "ordering": orderingData
            }
        })).unwrap()
        if (res) {
            if (id) {
                navigate(`/forum/${id}/settings`)
            } else {
                navigate(`/forum/settings`)
            }
        }
    }
    const conf = {
        title: 'Moderators',
        btnTitle: 'Add Moderators',
        btnAction: openModal
    }

    if (isLoading) return <div className="wrapper header-fixed sidebar-left-fixed flex items-center justify-center flex-auto">
        <Loader />
    </div>

    return (
        <div>
            <CreateFormHeader config={conf} />

            <div className="wrapper header-fixed sidebar-left-fixed">
                <main className="content">
                    <div className="quiz pt-0">
                        <div className="quiz__content pt-[20px]">
                            <div className="quiz__body quiz__body--start">
                                <div className="container container--lg">
                                    <div className="row information-gutters">
                                        <div className="col-12">
                                            <div className="group gap--lg md:flex-nowrap">
                                                <p className="text--md font-medium min-w-[140px] md:text-right">Moderators
                                                    ({moderators?.length})</p>
                                                <div className="w-full overflow-hidden">
                                                    {moderators?.length > 0 ? 
                                                        <SortModerators items={moderators} setItems={setModerators} removeHandler={removeHandler} /> 
                                                    : null}
                                                    <button onClick={openModal}
                                                        className="btn btn--primary btn--xs radius-5 gap-[10px]">
                                                        <span className="ico">
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <g clipPath="url(#clip0_264_6175)">
                                                                    <path d="M3.33301 10H16.6663" stroke="#191919" strokeWidth="1.5"
                                                                        strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    <path d="M10 3.3335V16.6668" stroke="#191919" strokeWidth="1.5"
                                                                        strokeLinecap="round" strokeLinejoin="round"></path>
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_264_6175">
                                                                        <rect width="20" height="20" fill="white"></rect>
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </span>
                                                        <span className="info text--sm">Add Moderator</span>
                                                    </button>
                                                    <p className="text--md color-secondary mt-[16px]">Add moderators to
                                                        your forum to regulate users subscribed to your forum</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="quiz__footer bg-white">
                                <div className="container">
                                    <Link to={'/forums'} className="btn btn--shadow gap-[10px] btn--md radius-5">
                                        <span className="info text-[13px] font-[500]">Cancel</span>
                                    </Link>
                                    <button type='button' onClick={submitHandler} className="btn btn--primary gap-[10px] btn--md radius-5">
                                        <span className="info text-[13px] font-[500] sm:flex hidden">Continue</span>
                                        <span className="ico">
                                            <ArrowRightIcon />
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>

            {openAddModer && <AddModeratorModal open={openAddModer} handelClose={closeModal} />}

        </div>
    )
}
