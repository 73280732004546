import React, { useEffect, useRef, useState } from 'react'
import ModalTemplate from './ModalTemplate'
import InputGroup from '../UI/formElements/InputGroup'
import api from '../../api'
import { toast } from 'react-toastify'
import ButtonLoader from '../UI/ButtonLoader'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import {
    ClassicEditor,
    AccessibilityHelp,
    Alignment,
    AutoImage,
    AutoLink,
    Autosave,
    Base64UploadAdapter,
    BlockQuote,
    Bold,
    Essentials,
    GeneralHtmlSupport,
    Heading,
    HtmlComment,
    ImageBlock,
    ImageCaption,
    ImageInline,
    ImageInsert,
    ImageInsertViaUrl,
    ImageResize,
    ImageStyle,
    ImageToolbar,
    ImageUpload,
    Indent,
    IndentBlock,
    Italic,
    Link,
    LinkImage,
    List,
    ListProperties,
    MediaEmbed,
    Paragraph,
    PasteFromOffice,
    RemoveFormat,
    SelectAll,
    Table,
    TableCaption,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar,
    TodoList,
    Underline,
    Undo
} from 'ckeditor5';

const SaveTableModal = ({ open, handelClose, editorRef }) => {
    const [title, setTitle] = useState('')
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(false);

    const editorRefTest = useRef(null);
    const editorConfig = {
        plugins: [
            AccessibilityHelp,
            Alignment,
            AutoImage,
            AutoLink,
            Autosave,
            // Base64UploadAdapter,
            BlockQuote,
            Bold,
            Essentials,
            GeneralHtmlSupport,
            Heading,
            HtmlComment,
            ImageBlock,
            ImageCaption,
            ImageInline,
            ImageInsert,
            ImageInsertViaUrl,
            ImageResize,
            ImageStyle,
            ImageToolbar,
            ImageUpload,
            Indent,
            IndentBlock,
            Italic,
            Link,
            LinkImage,
            List,
            ListProperties,
            MediaEmbed,
            Paragraph,
            PasteFromOffice,
            RemoveFormat,
            SelectAll,
            Table,
            TableCaption,
            TableCellProperties,
            TableColumnResize,
            TableProperties,
            TableToolbar,
            TodoList,
            Underline,
            Undo,
        ],
        heading: {
            options: [
                {
                    model: 'paragraph',
                    title: 'Paragraph',
                    class: 'ck-heading_paragraph'
                },
                {
                    model: 'heading1',
                    view: 'h1',
                    title: 'Heading 1',
                    class: 'ck-heading_heading1'
                },
                {
                    model: 'heading2',
                    view: 'h2',
                    title: 'Heading 2',
                    class: 'ck-heading_heading2'
                },
                {
                    model: 'heading3',
                    view: 'h3',
                    title: 'Heading 3',
                    class: 'ck-heading_heading3'
                },
                {
                    model: 'heading4',
                    view: 'h4',
                    title: 'Heading 4',
                    class: 'ck-heading_heading4'
                },
                {
                    model: 'heading5',
                    view: 'h5',
                    title: 'Heading 5',
                    class: 'ck-heading_heading5'
                },
                {
                    model: 'heading6',
                    view: 'h6',
                    title: 'Heading 6',
                    class: 'ck-heading_heading6'
                }
            ]
        },
        htmlSupport: {
            allow: [
                {
                    name: /^.*$/,
                    styles: true,
                    attributes: true,
                    classes: true
                }
            ]
        },
        image: {
            resizeOptions: [
                {
                    name: 'resizeImage:original',
                    value: null,
                    label: 'Original'
                },
                {
                    name: 'resizeImage:10',
                    value: '10',
                    label: '10%'
                },
                {
                    name: 'resizeImage:25',
                    value: '25',
                    label: '25%'
                },
                {
                    name: 'resizeImage:50',
                    value: '50',
                    label: '50%'
                },
                {
                    name: 'resizeImage:75',
                    value: '75',
                    label: '75%'
                },
                {
                    name: 'resizeImage:100',
                    value: '100',
                    label: '100%'
                }
            ],
            toolbar: [
                'toggleImageCaption',
                'imageTextAlternative',
                '|',
                'imageStyle:inline',
                'imageStyle:wrapText',
                'imageStyle:breakText',
                '|',
                'resizeImage'
            ]
        },
        link: {
            addTargetToExternalLinks: true,
            defaultProtocol: 'https://',
            // decorators: {
            // 	toggleDownloadable: {
            // 		mode: 'manual',
            // 		label: 'Downloadable',
            // 		attributes: {
            // 			download: 'file'
            // 		}
            // 	}
            // }
        },
        placeholder: 'Type or paste your content here!',
        table: {
            contentToolbar: [
                'tableColumn',
                'tableRow',
                'mergeTableCells',
                'tableProperties',
                'tableCellProperties',
                'saveTableButton',
                // 'OptionsTableButton'
            ],
        },
        resize_dir: "both"
    };
    const [editorData, setEditorData] = useState('')

    const handleEditorChange = (editor) => {
        const data = editor.getData();
        setEditorData(data);
    };

    const getSelectedTable = () => {
        if (editorRef.current) {
            const editor = editorRef.current;
            const model = editor.model;
            const selection = model.document.selection;


            const ranges = Array.from(selection.getRanges());
            // Проверяем, находятся ли все выделенные диапазоны в одной таблице
            // Получаем таблицы для всех выделенных ячеек
            const tables = ranges
                .map(range => {
                    // Проверяем, что начальная и конечная позиции диапазона находятся в ячейке таблицы
                    const startCell = range.start.findAncestor('table');
                    const endCell = range.end.findAncestor('table');

                    if (startCell && startCell === endCell) {
                        // Возвращаем таблицу, если начальная и конечная позиции находятся в одной ячейке таблицы
                        return startCell;
                    }
                    return null;
                })
                .filter(table => table)

            // // Оставляем только уникальные таблицы, чтобы проверить, что все ячейки принадлежат одной таблице
            const uniqueTables = [...new Set(tables)];
            
            console.log(ranges, tables)
            if (uniqueTables.length === 1 && uniqueTables[0]) {
                const table = uniqueTables[0];
                const view = editor.editing.mapper.toViewElement(table);
                const domTable = editor.editing.view.domConverter.mapViewToDom(view);
                return domTable;
            }

            // Проверяем, находится ли фокус в таблице
            // const tableCell = selection.getFirstPosition().findAncestor('tableCell');

            // if (tableCell) {
            //     const table = tableCell.findAncestor('table');
            //     if (table) {
            //         const view = editor.editing.mapper.toViewElement(table);


            //         const domTable = editor.editing.view.domConverter.mapViewToDom(view);

            //         return domTable;
            //     }
            // }
            return null;
        }
    }
    const getContentForInsert = (htmlString) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
        const table = doc.querySelector('table');
        // Находим все элементы с классами .ck-table-column-resizer и удаляем их
        const editableElements = doc.querySelectorAll('[contenteditable]');

        // Устанавливаем значение contenteditable="false" для всех найденных элементов
        editableElements.forEach(element => {
            element.removeAttribute('contenteditable');
        });

        // Удаляем все элементы с классом .ck-table-column-resizer
        const resizers = table.querySelectorAll('.ck-table-column-resizer');
        resizers.forEach(resizer => resizer.remove());
        // Удаляем все элементы с классом .ck-table-column-resizer
        const resizersRow = table.querySelectorAll('.table-row-resize-handle');
        resizersRow.forEach(resizer => resizer.remove());

        // Находим все элементы с классом .ck-table-bogus-paragraph
        const bogusParagraphs = table.querySelectorAll('.ck-table-bogus-paragraph');
        bogusParagraphs.forEach(paragraph => {
            // Перемещаем все дочерние элементы из .ck-table-bogus-paragraph в его родительский элемент
            while (paragraph.firstChild) {
                paragraph.parentNode.insertBefore(paragraph.firstChild, paragraph);
            }
            // Удаляем сам элемент .ck-table-bogus-paragraph
            paragraph.remove();
        });

        // Возвращаем обновленную таблицу в виде HTML-строки
        return table.outerHTML;
    }

    useEffect(() => {
        if (editorRef.current) {
            const tableElement = getSelectedTable();
            console.log(tableElement)
            // editorRefTest.current.setData(getContentForInsert(tableElement.outerHTML))
            // setEditorData(getContentForInsert(tableElement.outerHTML))
        }
    }, [editorRef])

    const saveHandler = async () => {
        if (title.trim() === '') {
            setError('Title is required')
            return
        }
        setIsLoading(true)
        const res = await api.saveTables.createTable(
            {
                "title": title,
                "content": editorData
            }
        )
        console.log(res)
        if (res.success) {

        }
        if (!res.success) {
            setError('This title already exists')
        }
        setIsLoading(false)
    }
    return (
        <ModalTemplate open={open} handelClose={handelClose} modalTheme='z-[9999]' showHeader={true}>
            <div className="modal__body pt-0">
                <div className="modal__body-heading text-center">
                    <h2>Save table</h2>
                </div>
                <div className="row g-24">
                    <div className="col-12">
                        <InputGroup
                            wrapperClasses='input--lg'
                            label={'Table Title'}
                            inputClasses={'input--outline p-[16px]'}
                            placeholder={'Enter table title...'}
                            value={title}
                            onChange={({ target }) => {
                                if (target.value.trim() === '') {
                                    setError('Title is required')
                                } else {
                                    setError(null)
                                }
                                setTitle(target.value)
                            }}
                            error={error}
                            errorMessage={error}
                        />
                    </div>
                </div>
            </div>
            <div className="modal__footer">
                <button
                    type='button'
                    onClick={handelClose}
                    className={`btn btn--shadow btn--md radius-5 ${isLoading ? 'disabled' : ''}`}
                    disabled={isLoading}
                >
                    Cancel
                </button>
                <button type='button'
                    onClick={saveHandler}
                    className={`btn btn--primary btn--md radius-5 md:ml-auto ${isLoading ? 'disabled' : ''}`}
                    disabled={isLoading}
                >
                    {isLoading ? <ButtonLoader /> : 'Save'}
                </button>
            </div>
            <div className="hidden">
                <CKEditor
                    editor={ClassicEditor}
                    config={editorConfig}
                    data={editorData}
                    onChange={(event, editor) => {
                        handleEditorChange(editor)
                    }}
                    onReady={(editor) => {
                        editorRefTest.current = editor;
                    }}
                />
            </div>

        </ModalTemplate>
    )
}

export default SaveTableModal
