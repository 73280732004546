import { ForumSideBar } from "./ForumSideBar";
import { ForumLayout } from "../../layouts/ForumLayout";
import { useEffect, useMemo, useState } from "react";
import { AddThreadModal } from "../../components/Modals/AddThreadModal";
import SelectGroup from "../../components/UI/formElements/SelectGroup";
import Pagination from "../../components/parts/Pagination";
import { filterOptions } from "../../helpers/constants";
import { useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getAllThreadsFeth } from "../../store/forums/forumsThunk";
import PageLoader from "../../components/parts/PageLoader";
import ThreadItem from "../../components/parts/ThreadItem";
import useDebounce from "../../hooks/useDebounce";
import InputSearch from "../../components/UI/formElements/InputSearch";
import { Dropdown } from "../../components/parts/Dropdown";
import { getFiltersByName } from "../../store/filters/filtersThukns";
import { getFilteres } from "../../store/filters/filtersSlice";
import CheckBoxGroup from "../../components/UI/formElements/CheckBoxGroup";
import { setThread } from "../../store/forums/forumsSlice";
import CardThread from "../../components/parts/Cards/CardThread";
import DeleteModal from "../../components/Modals/DeleteModal";
import { ReactComponent as Report } from "../../assets/img/report-icon.svg";
import ReportModal from "../../components/Modals/ReportModal";
import { getMe } from "../../store/auth/authSlice";


export const ForumPage = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const me = useSelector(getMe)
    const { forumOne, isLoading, pagination, threadsAll } = useSelector(state => state.forums)
    const [openAddThread, setOpenAddThread] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const [reportModal, setReportModal] = useState(false);
    const [searchIsShow, setSearchIsShow] = useState(false)
    const formatOptionsQuery = (value) => {
		return value?.length
			? value.split(',').filter(Boolean) || []
			: []
	}
	const params = useMemo(() => ({
		sort: searchParams.get('sort') || 'latest',
		category: formatOptionsQuery(searchParams.get('category')) || [],
		search: searchParams.get("search") || ""
	}), [searchParams])
    const systems = useSelector(getFilteres);
    const openModal = () => {
        dispatch(setThread(null))
        setOpenAddThread(true)
    }
    const closeModal = () => {
        setOpenAddThread(false)
    }
    const loadThreads = () => {
        const res = dispatch(getAllThreadsFeth({ id: id, qwery: searchParams.toString() }))
        if (res) {
            window.scrollTo(0, 0)
        }
    }
    useEffect(() => {
        if (id) {
            loadThreads()
        }
    }, [id, searchParams])
    const handleSortChange = (option) => {
        setSearchParams({
            ...params,
            sort: option.value,
        });
    }
    const handleFilterChange = (options) => {
        setSearchParams({
            ...params,
            category: params.category.includes(options.name)
                ? params.category.filter((cat) => cat !== options.name).join(',')
                : [...params.category, options.name].join(','),
        });
    }
    const isChecked = (option) => params.category.includes(option.name);
    const debouncedSearchChange = useDebounce((value) => {
        setSearchParams({
            ...params,
            search: value
        });
    }, 500);
    const handleSearchChange = (e) => {
        const { value } = e.target;
        debouncedSearchChange(value);
    };
    useEffect(() => {
        dispatch(getFiltersByName('thread'))
    }, [dispatch])
    
    return (
        <div className="w-full h-full d-flex flex-col">
            
            <div className="page-heading mb-[30px] ">
                <div className="page-heading__item">
                    <h3 className="mr-[24px]">{forumOne?.name}</h3>
                </div>
                <div className="page-heading__item relative">
                    <SelectGroup
                        wrapperClasses={'input--sm'}
                        options={filterOptions}
                        defaultValue={filterOptions[0]}
                        onChange={handleSortChange}
                    />
                    <div className="row-group gap--xs">
                        <button onClick={openModal} className="btn btn--primary btn--md radius-5 gap--xs">
                            <span className="ico">
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_707_35011)">
                                        <path d="M3.6665 11H18.3332" stroke="currentColor"
                                            strokeWidth="1.5" strokeLinecap="round"
                                            strokeLinejoin="round"></path>
                                        <path d="M11 3.6665V18.3332" stroke="currentColor"
                                            strokeWidth="1.5" strokeLinecap="round"
                                            strokeLinejoin="round"></path>
                                    </g>
                                </svg>
                            </span>
                            <span className="info">Create Thread</span>
                        </button>
                        <button
                            type="button"
                            className="btn btn--md btn--transparent radius-5"
                            onClick={() => setSearchIsShow(true)}
                        >
                            <span className="ico">
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="10.1375" cy="10.137" r="6.47345" stroke="currentColor"
                                        strokeWidth="1.5" strokeLinecap="round"
                                        strokeLinejoin="round"></circle>
                                    <path d="M18.3372 18.3367L14.7148 14.7144" stroke="currentColor"
                                        strokeWidth="1.5" strokeLinecap="round"
                                        strokeLinejoin="round"></path>
                                </svg>
                            </span>
                        </button>

                        <Dropdown
                            wrapperClass={""}
                            button={
                                <div className="btn btn--md btn--transparent radius-5">
                                    <span className="ico">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                d="M3.35338 8.90736C2.96532 8.48458 2.75 7.93159 2.75 7.35772V5.5C2.75 3.98122 6.44365 2.75 11 2.75C15.5563 2.75 19.25 3.98122 19.25 5.5V7.35772C19.25 7.93159 19.0347 8.48458 18.6466 8.90736L13.75 14.2421V17.213C13.75 18.338 12.838 19.25 11.713 19.25H10.287C9.16202 19.25 8.25001 18.338 8.25 17.213V14.242L3.35338 8.90736Z"
                                                stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                                                strokeLinejoin="round"></path>
                                        </svg>
                                    </span>
                                </div>
                            }
                            body={
                                <ul className='w-[180px]'>

                                    {systems?.map((option, idx) => (
                                        <li key={idx}>
                                            <CheckBoxGroup
                                                wrapperClasses="flex-auto p-3 max-w-none"
                                                value={option.name}
                                                checked={isChecked(option)}
                                                onChange={() => handleFilterChange(option)}
                                            >
                                                <p className="check-text">{option.name}</p>
                                            </CheckBoxGroup>
                                        </li>
                                    ))}

                                </ul>
                            }
                        />
                        <button
                            type="button"
                            className="btn btn--md btn--transparent radius-5"
                            onClick={() => { setReportModal(prev => !prev) }}
                        >
                            <span className="ico">
                                <Report />
                            </span>
                        </button>
                        {searchIsShow && <InputSearch className='input--md w-full thread-search'
                            defaultValue={params.search}
                            onChange={handleSearchChange}
                            closeButton={true}
                            closeHandler={() => {
                                debouncedSearchChange('')
                                setSearchIsShow(false)
                            }}
                        />}
                    </div>
                </div>



            </div>
            <div className="threads-list flex-1">
                {threadsAll && threadsAll.length > 0 ?
                    threadsAll.map((thread, index) => (<CardThread data={thread} key={index} />))
                    : isLoading ?
                        <PageLoader /> :
                        <h2>Not found Threads</h2>}
            </div>
            <Pagination {...pagination} params={params} />

            {openAddThread && <AddThreadModal open={openAddThread} forumName={forumOne?.name} title={'Create Thread'} handleClose={closeModal}  />}
            {reportModal && <ReportModal open={reportModal} handelClose={() => { setReportModal(prev => !prev) }} refType={'Forum'} />}
        </div>
    )
}